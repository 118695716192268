import { useState, useRef, useEffect } from "react";
import { FloatingPanel } from "antd-mobile";
import styled from "styled-components";

// IN: drawerLocation: 0..2: index for anchors.
//                     0 means to put the drawer at the lowest anchor on the page
//                     -1 means to hide the drawer
function Drawer({
  drawerLocation,
  setDrawerLocation,
  setMb = undefined,
  isInitialExpenseReview = false,
  isPageLayoutComplete,
  setIsDrawerShort,
  children,
}) {
  const drawerRef = useRef(null);

  // anchors are what height the top of the drawer can be dragged to.
  // unit is in px.
  // anchor at 0 is the bottom of the window.
  const [anchors, setAnchors] = useState([0, 0, 0]);
  const [greenHeader, setGreenHeader] = useState(false);

  function setCoordinatesAnchors() {
    function getMyCoordinates(id) {
      let dom = document.getElementById(id);
      let ret = 0;
      if (dom) {
        ret = dom.getBoundingClientRect();
      }
      dom = null;
      return ret;
    }

    if (isInitialExpenseReview) {
      const reviewExpensesText = parseInt(
        getMyCoordinates("reviewExpensesText").bottom,
      );
      const donut = parseInt(getMyCoordinates("donut").bottom);
      const progressBar = parseInt(getMyCoordinates("progressBar").bottom);

      setAnchors([
        56,
        window.innerHeight - donut - 20,
        //window.innerHeight - Math.max(donut, reviewExpensesText),
        window.innerHeight - progressBar,
      ]);
    } else {
      const topOfBottomNavBar = parseInt(getMyCoordinates("bottomNavBar").top);
      const donut = parseInt(getMyCoordinates("donut").bottom);
      const topNavBar = parseInt(getMyCoordinates("topNavBar").top);
      setAnchors([
        // + 56 is for the drawer handle --header-height
        window.innerHeight - topOfBottomNavBar + 56,
        window.innerHeight - donut - 20,
        window.innerHeight - topNavBar,
      ]);
    }
  }

  useEffect(() => {
    console.log("anchors are");
    console.log(anchors);
    // anchors is where the drawer wants to snap to whenever you drag it close to the anchor.
    // setHeight() is good if you want to immediately set it to that location, this once.
  }, [anchors]);

  const handleFloatingPanelHeightChange = (height, animating) => {
    // As user drags the drawer, if it goes above a certain height,
    // then switch to tall drawer, or else switch to short drawer.
    if (height > anchors[1] + 40) {
      if (setIsDrawerShort) {
        setIsDrawerShort(false);
      }
      setGreenHeader(true);
    } else {
      if (setIsDrawerShort) {
        setIsDrawerShort(true);
      }
      setGreenHeader(false);
    }
    if (!animating) {
      // Once the panel stops moving, update the margin-bottom
      // for the panel content and update drawerLocation.
      if (isInitialExpenseReview) {
        if (height === anchors[2]) {
          // top height, no BottomNavBar
          setMb("1px");
        } else if (height === anchors[1]) {
          // middle height
          const newMb = anchors[2] - anchors[1] + 1;
          setMb(newMb + "px");
        }
      } else {
        if (height === anchors[2]) {
          // top height.
          // BottomNavBar is 72px tall.  margin-bottom keeps the div above the BottomNavBar.
          setMb("88px");
          setDrawerLocation(2);
        } else if (height === anchors[1]) {
          // middle height
          const newMb = anchors[2] - anchors[1] + 88;
          setMb(newMb + "px");
          setDrawerLocation(1);
        }
      }
    }
  };

  useEffect(() => {
    // Is this getting called before the layout is complete? no

    // The TopNavBarWheels, BottomNavBar, and Tracker are in fixed locations.
    // Once the initial rendering is complete and DOM elements drawn,
    // then this useEffect is called, so the locations are correct.
    setCoordinatesAnchors();
  }, [isPageLayoutComplete]);

  useEffect(() => {
    //console.log(`Drawer Component says:  drawerLocation = ${drawerLocation}`);
    //console.log("anchors");
    //console.log(anchors);
    //console.log(`Height will be ${anchors[drawerLocation]}`);

    console.assert(
      drawerLocation >= -1 && drawerLocation <= 2,
      "drawerLocation out of range",
    );
    if (drawerLocation >= 0) {
      drawerRef.current.setHeight(anchors[drawerLocation]);
    } else {
      // make drawer disappear
      drawerRef.current.setHeight(0);
    }
  }, [drawerLocation, anchors]);

  return (
    <StyledPanel
      ref={drawerRef}
      anchors={anchors.slice(1)}
      handleDraggingOfContent={false}
      style={{ "--header-height": "28px" /* default is 28px */ }}
      onHeightChange={handleFloatingPanelHeightChange}
      greenHeader={greenHeader}
    >
      {children}
    </StyledPanel>
  );
}

export default Drawer;

const StyledPanel = styled(FloatingPanel)`
  background: #d9f7bd; //only when drawer is big
  border-radius: 20px 20px 0 0;
  box-shadow: 0px -6px 6px 0px #d4d4d466;

  .adm-floating-panel-header {
    ${(props) =>
      props.greenHeader
        ? `background-color: #D9F7BD;`
        : ``}//only when drawer is big
  }
  .adm-floating-panel-bar {
    width: 100px !important;
  }
`;
