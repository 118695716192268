import ShortContent from "./ShortContent";
import TallContent from "./TallContent";

export default function DrawerContent({
  isDrawerShort,
  category,
  dateString,
  budgetInfo,
  editPlanned,
  editDate,
  tableData,
  editRow,
  onFinish,
  setEditID,
  unsortedAmount,
  basicView = false,
  switchCategory,
  onFinishPlanned,
  viewMonth,
  viewYear,
  openTallDrawer,
  openShortDrawer,
}) {
  const isTall = !isDrawerShort;
  if (basicView) {
    return (
      <ShortContent
        basicView={basicView}
        unsortedAmount={unsortedAmount}
        openTallDrawer={openTallDrawer}
      />
    );
  }
  return (
    <>
      {!isTall && (
        <ShortContent
          dateString={dateString}
          category={category}
          budgetInfo={budgetInfo}
          unsortedAmount={unsortedAmount}
          openTallDrawer={openTallDrawer}
        />
      )}
      {isTall && (
        <TallContent
          editDate={editDate}
          dateString={dateString}
          category={category}
          tableData={tableData}
          onFinish={onFinish}
          setEditID={setEditID}
          budgetInfo={budgetInfo}
          switchCategory={switchCategory}
          onFinishPlanned={onFinishPlanned}
          viewMonth={viewMonth}
          viewYear={viewYear}
          openShortDrawer={openShortDrawer}
        />
      )}
    </>
  );
}
