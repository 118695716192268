import styled from "styled-components";

export default function TotalApplied({ total }) {
  return (
    <MainContainer>
      <p className="bold">${total}</p>
      <p className="small">Total Applied</p>
    </MainContainer>
  );
}

const MainContainer = styled.div`
  border: 1px solid #ff773d;
  border-radius: 18px;
  margin-top: 10px;
  box-shadow: 0px 2px 10px 0px #b6b6b6cc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #0b0d16;
  padding: 0.5rem;
  p {
    margin: 0;
  }
  .bold {
    font-weight: 800;
    font-size: 36px;
  }
  .small {
    font-weight: 500;
    font-size: 12px;
  }
`;
