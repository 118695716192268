import MoneyFlowMenu, { DebugMenu } from "./MoneyFlowMenu";
import { isDevelopment } from "../../functions/environment";
import { ReactComponent as MoneyflowIcon } from "../../assets/img/Logo/moneyflow-icon.svg";
// MoneyflowLogo = MoneyflowIcon + the word "moneyflow"
import { ReactComponent as MoneyflowLogo } from "../../assets/img/Logo/moneyflow-logo.svg";

import styled, { css } from "styled-components";

function MoneyFlowHeader({ showMenu = true, showLogoWord = false }) {
  return isDevelopment() ? <DebugMenu /> : null;
  /*
  return (
    <Top>
      <Header>
        {showLogoWord ? <StyledMoneyflowLogo /> : <StyledMoneyflowIcon />}
        {showMenu && <MoneyFlowMenu />}
      </Header>
    </Top>
  );
  */
}

export function MoneyFlowHeaderNoMenu() {
  return null;
  //return <MoneyFlowHeader showMenu={false} showLogoWord={true} />;
}

export default MoneyFlowHeader;

const Top = styled.div`
  width: 100%;
  position: relative;
`;

const Header = styled.header`
  display: flex;
  height: auto;
  align-items: center;
  justify-content: space-between;
  padding: var(--padding-s) 0 0 0;
  position: relative;
  align-self: stretch;
  background-color: transparent;
`;

const moneyflowLogoMixin = css`
  height: var(--moneyflow-logo-height);
  /* on mobile, height is 6.3vh = 58px/921px */

  /* for responsive desktop */
  /*
  @media (min-width: 1024px) {
    height: 12vh;
  }
  */
  /* for mobile look on ipad */
  @media (min-width: 500px) {
    height: 7vh;
  }
`;

const StyledMoneyflowLogo = styled(MoneyflowLogo)`
  ${moneyflowLogoMixin}
  /* #25b764 is the color of the money wheel */
  /* #1677ff is the color of Ant Design's primary button */
  path {
    fill: #1677ff;
    color: #1677ff;
  }
`;

const StyledMoneyflowIcon = styled(MoneyflowIcon)`
  ${moneyflowLogoMixin}
  /* #25b764 is the color of the money wheel */
  path {
    fill: #25b764;
    color: #25b764;
  }
`;
