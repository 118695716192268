import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useUser } from "../../state/UserProvider";
import { useCheckResponseFail } from "../../hooks/useCheckResponseFail";

import { ErrorNoticeBar } from "../../components/UI/ErrorNoticeBar";
import MoneyFlowHeader from "../../components/PageComponents/MoneyFlowHeader";
import { TopNavBarPersonal } from "../../components/PageComponents/TopNavBar";
import CoolFormInput from "../../components/UI/CoolFormInput";
import CoolFormInputEmail from "../../components/UI/CoolFormInputEmail";
import CoolFormInputName from "../../components/UI/CoolFormInputName";
import CoolFormInputPassword from "../../components/UI/CoolFormInputPassword";
import CoolFormInputPhone from "../../components/UI/CoolFormInputPhone";
import CoolFormInputUsername from "../../components/UI/CoolFormInputUsername";
import BottomNavBar from "../../components/PageComponents/BottomNavBar";

import * as SP from "../../styles/styledPageComponents";
import { PageTitle } from "../../styles/styledPageComponents";
import { Form, Button, Select } from "antd";
import "../../styles/IPadLook.css";
import { hasContent } from "../../functions/string";
import backend from "../../functions/backend";
import styled from "styled-components";

function ProfilePage() {
  const navigate = useNavigate();

  const [form1] = Form.useForm();
  const location = useLocation();
  const [formInitialValues, setFormInitialValues] = useState({});
  var [regionOptions, setRegionOptions] = useState([]);
  var [countryOptions, setCountryOptions] = useState([]);
  var [languageOptions, setLanguageOptions] = useState([]);
  var [timezoneOptions, setTimezoneOptions] = useState([]);
  const currentPage = location.state;
  // currentPage is null, if no state was passed via Link or navigate.
  const { currentUser, dispatchUser } = useUser();
  console.log("This is from the UserProvider: currentUser=", currentUser);

  const checkResponseFail = useCheckResponseFail();

  async function init() {
    let fields = {};
    if (currentUser) {
      const data = { id: currentUser.addresses };
      const response = await backend.get("/v1/address", data);
      checkResponseFail(response, "Failed to get address:");
      if (response.success) {
        if (response.items.length > 0) {
          fields.address_1 = response.items[0].line_1;
          fields.address_2 = response.items[0].line_2;
          fields.state = response.items[0].region;
          fields.city = response.items[0].city;
          fields.country = response.items[0].country_id;
          fields.zip_code = response.items[0].postal_code;
        }
      }

      fields.first_name = currentUser.first_name;
      fields.last_name = currentUser.last_name;
      fields.marital_status = currentUser.marital_status;
      fields.email_address = currentUser.email_address;
      fields.gender = currentUser.gender;
      // handle null currentUser.username
      fields.username = currentUser.username ? currentUser.username : "";
      fields.phone_number = currentUser.phone_number;
      fields.date_of_birth = currentUser.date_of_birth;
      fields.language = parseInt(currentUser.language_id);
      fields.timezone = parseInt(currentUser.timezone_id);
      if (currentUser.is_veteran) {
        fields.veteran_status = "veteran";
      } else {
        fields.veteran_status = "none";
      }
    }

    form1.setFieldsValue(fields);
    setFormInitialValues({ ...fields });

    getOptionsList("/v1/country?is_available=true", setCountryOptions);
    getOptionsList("/v1/language?is_available=true", setLanguageOptions);
    getOptionsList("/v1/timezone?is_available=true", setTimezoneOptions);

    // country_id is a number
    // region code might be a 2 letter US State abbrv
    // region name might be the full US State name
    getOptionsList("/v1/region?country_id=" + fields.country, setRegionOptions);
  }

  async function getOptionsList(url, setterFunction) {
    var response = await backend.get(url);
    var ret = [];
    for (var x in response.items) {
      ret.push({
        label: response.items[x].name,
        value: response.items[x].id,
      });
    }
    setterFunction(ret);
    // return ret;
  }

  const handleCountryChange = (value) => {
    // update the states/ provinces / regions available for the newly selected country
    getOptionsList("/v1/region?country_id=" + value, setRegionOptions);
  };

  useEffect(() => {
    init();
  }, []);

  async function onFinish(values) {
    const userSetValues = {
      id: currentUser.id,
      first_name: values.first_name,
      last_name: values.last_name,
      marital_status: values.marital_status,
      // send lowercased email to backend
      email_address: values.email_address.toLowerCase(),
      gender: values.gender,
      phone_number: values.phone_number,
      current_password: values.current_password,
      new_password: values.new_password,
      date_of_birth: values.date_of_birth,
      language_id: parseInt(values.language),
      timezone_id: parseInt(values.timezone),
    };
    if (values.username && values.username !== "") {
      userSetValues.username = values.username;
    }
    const dispatchUserSetValues = JSON.parse(JSON.stringify(userSetValues));
    dispatchUserSetValues.is_veteran = Boolean(
      values.veteran_status === "veteran",
    );

    userSetValues.military_status = values.veteran_status;

    const addressSetValue = {
      id: currentUser.addresses,
      line_1: values.address_1,
      line_2: values.address_2,
      region: values.state,
      city: values.city,
      country_id: parseInt(values.country),
      postal_code: values.zip_code,
    };

    dispatchUser({
      type: "PROFILE",
      payload: dispatchUserSetValues,
    });
    const response = await backend.patch("/v1/user", userSetValues);
    checkResponseFail(response, "Failed to patch user:");
    const responseAddress = await backend.patch("/v1/address", addressSetValue);
    checkResponseFail(responseAddress, "Failed to patch address:");

    if (!responseAddress.success) {
      console.warn("Fail: patch address.  addressSetValue =");
      console.warn(addressSetValue);
    } else {
      console.log("Success: patch address");
    }

    if (responseAddress.success && response.success) {
      navigate(currentPage ? currentPage : "/money-wheel");
    } else {
      document.getElementsByTagName("HTML")[0].scrollTop = 0;
    }
  }

  function navBack() {
    navigate(currentPage ? currentPage : "/money-wheel");
  }

  function onFinishFailed(errorInfo) {}

  return (
    <div className="screen">
      <MoneyFlowHeader />
      <ErrorNoticeBar />
      <TopNavBarPersonal />
      <div className="tracker-form-split">
        <SP.ScrollingAboveBottomNavBar>
          <Form
            style={{ maxWidth: 430, width: "100%" }}
            layout="vertical"
            name="form_registration"
            form={form1}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <PageTitle>Profile</PageTitle>

            <SP.FormItemContainer>
              <CoolFormInputPassword
                name="current_password"
                placeholder="Current Password"
              />
              <CoolFormInputPassword
                name="new_password"
                placeholder="New Password"
              />
              <CoolFormInputName
                name="first_name"
                placeholder="First Name"
                hasInitialValue={hasContent(formInitialValues.first_name)}
              />
              <CoolFormInputName
                name="last_name"
                placeholder="Last Name"
                hasInitialValue={hasContent(formInitialValues.last_name)}
              />
              <CoolFormInputEmail
                placeholder="Email"
                hasInitialValue={true}
                required={true}
              />
              <CoolFormInputUsername
                name="username"
                placeholder="Username"
                hasInitialValue={hasContent(formInitialValues.username)}
              />
              <CoolFormInputPhone
                hasInitialValue={hasContent(formInitialValues.phone_number)}
                required={true}
              />
              <CoolFormInput
                name="date_of_birth"
                placeholder="Date of Birth (YYYY-MM-DD)"
                hasInitialValue={hasContent(formInitialValues.date_of_birth)}
              />
              <CoolFormInput
                name="address_1"
                placeholder="Address 1"
                hasInitialValue={hasContent(formInitialValues.address_1)}
              />
              <CoolFormInput
                name="address_2"
                placeholder="Address 2"
                hasInitialValue={hasContent(formInitialValues.address_2)}
              />
              <CoolFormInput
                name="city"
                placeholder="City"
                hasInitialValue={hasContent(formInitialValues.city)}
              />
              <CoolFormInput
                name="zip_code"
                placeholder="Postal / Zip Code"
                hasInitialValue={hasContent(formInitialValues.zip_code)}
              />
              <Form.Item
                name="state"
                label="State / Province / Region"
                hasFeedback
              >
                <Select options={regionOptions}></Select>
              </Form.Item>
              <Form.Item name="country" label="Country" hasFeedback>
                <Select
                  options={countryOptions}
                  onChange={handleCountryChange}
                ></Select>
              </Form.Item>
              <Form.Item name="timezone" label="Time Zone" hasFeedback>
                <Select options={timezoneOptions}></Select>
              </Form.Item>
              <Form.Item name="language" label="Language" hasFeedback>
                <Select options={languageOptions}></Select>
              </Form.Item>
              <Form.Item
                name="marital_status"
                label="Relationship Status"
                hasFeedback
              >
                <Select
                  options={[
                    { value: "unspecified", label: "Unspecified" },
                    { value: "single", label: "Single" },
                    { value: "partnered", label: "Partnered" },
                    { value: "married", label: "Married" },
                  ]}
                />
              </Form.Item>
              <Form.Item name="gender" label="Gender" hasFeedback>
                <Select
                  options={[
                    { value: "unspecified", label: "Unspecified" },
                    { value: "female", label: "Female" },
                    { value: "male", label: "Male" },
                    { value: "nonbinary", label: "Nonbinary" },
                  ]}
                />
              </Form.Item>
              <Form.Item
                name="veteran_status"
                label="Military Status"
                hasFeedback
              >
                <Select
                  options={[
                    { value: "unspecified", label: "Unspecified" },
                    { value: "none", label: "None" },
                    { value: "active", label: "Active" },
                    { value: "veteran", label: "Veteran" },
                  ]}
                />
              </Form.Item>
            </SP.FormItemContainer>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Save Profile
              </Button>
            </Form.Item>
            <SpacerForSelect></SpacerForSelect>
          </Form>
        </SP.ScrollingAboveBottomNavBar>
      </div>

      <BottomNavBar />
    </div>
  );
}

export default ProfilePage;

// An open Select at the bottom of the page will be hidden by the BottomNavBar.
// So add this spacer for pages that have a Select at the bottom of the page.
const SpacerForSelect = styled.div`
  height: 80px;
`;
