import { MoneyFlowHeaderNoMenu } from "../../components/PageComponents/MoneyFlowHeader";
import GreenGradientBackground from "../../components/UI/GreenGradientBackground";
import MoneyFlowFooter from "../../components/PageComponents/MoneyFlowFooter";
import { MainContainer } from "../../styles/styledLoginComponents";
import "../../styles/IPadLook.css";

// This page appears right after user clicks on the Register button
export default function SentRegistrationEmail() {
  return (
    <div className="screen">
      <MoneyFlowHeaderNoMenu />
      <MainContainer justifyContent={"flex-start"}>
        <GreenGradientBackground bottom={true} />
        <h1 style={{ paddingTop: "1em" }}>
          Thank you for registering at MoneyFlow!
        </h1>
        <div style={{ textAlign: "center" }}>
          <p>
            We have sent you an email with a link to confirm your registration.
            Please click on the link provided to activate your account.
          </p>
        </div>
        <div
          style={{
            marginBottom: "1em",
            textAlign: "center",
            marginTop: "auto",
          }}
        >
          <MoneyFlowFooter />
        </div>
      </MainContainer>
    </div>
  );
}
