import { Button, Form, Radio } from "antd";
import { useState } from "react";

import {
  AntGreyTextEntry,
  AntDateSelector,
  GreyDropDown,
} from "../../components/DrawerComponents/splitComponents";
import styled from "styled-components";

export default function NewAssetForm({ form, onFinish, closeModal }) {
  const [category, setCategory] = useState(0);
  const options = [
    {
      label: "Personal Property",
      value: "personal_property",
    },
    {
      label: "Real Property",
      value: "real_property",
    },
    {
      label: "Vehicle",
      value: "vehicle",
    },
  ];
  return (
    <Form
      form={form}
      name={"NewGoalForm"}
      onFinish={onFinish}
      onFinishFailed={closeModal}
      initialValues={{ category: category }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          paddingTop: "20px",
        }}
      >
        <>
          <p>Add New Asset</p>
          <AntGreyTextEntry label="Label" name="name" />
          <AntGreyTextEntry label="Cost" name="value" />
          <AntGreyTextEntry label="Notes" name="note" />

          <AntDateSelector label="Date Acquired" name="acquisition_date" />
          <Form.Item name="asset_type" initialValue={"personal_property"}>
            <Radio.Group
              options={options}
              optionType="button"
              buttonStyle="solid"
              style={{ margin: "auto" }}
            />
          </Form.Item>

          <Form.Item name="goal_category_id" initialValue={category} hidden>
            <input type="hidden" />
          </Form.Item>
          <GreyDropDown
            category={category}
            setCategory={(value) => {
              setCategory(value);
              form.setFieldsValue({ goal_category_id: value });
            }}
          />
        </>

        <div
          style={{ display: "flex", alignItems: "center", marginTop: "20px" }}
        >
          <AddInfoButton
            className="footer"
            type="button"
            onClick={() => {
              closeModal();
            }}
          >
            Cancel
          </AddInfoButton>
          <SubmitButton>Save!</SubmitButton>
        </div>
      </div>
    </Form>
  );
}

const SubmitButton = styled.button`
  background: linear-gradient(180deg, #3590ff 0%, #8dc1ff 171.11%);

  color: white;
  border-radius: 15px;
  border: none;

  box-shadow: 0px 2px 6px 0px #2180f473;

  padding-top: 8px;
  padding-bottom: 8px;
  margin-left: auto;
  width: 45%;
  heigh: 100%;
`;

const AddInfoButton = styled.button`
  border: none;
  border-radius: 100px;
  margin-left: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #ffffff;
  padding: 8px;
  font-size: 16px;
  font-weight: 500;
  color: #0b0d16bf;
  border: 1px solid #0b0d1640;
  box-shadow: 0px 2px 20px 0px #0b0d161a;
  &.footer {
    margin: 0;
    // margin-left: 0px;
    font-size: 18px;
    color: #0b0d16bf;
    width: 45%;
    height: 100%;
  }
`;
