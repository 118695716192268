import gsap from "gsap";

// The location we snap (animate) back to after release of expense
export const origin = {
  x: 0,
  y: 0,
};

// Show the new expense amount during this animation.
// IN: nextExpense contains the new expense amount.
// This is better than relying on draggableExpense, which is
// asynchronously updated after an API call and thus not
// perfectly timed to the animation.
function animateExpenseReturnToWheelCenter(nextExpense, setCenter) {
  const runOnStart = (nextExpense) => {
    setCenter(nextExpense);
  };

  gsap.fromTo(
    "#expense",
    {
      x: origin.x,
      y: origin.y,
    },
    {
      x: origin.x,
      y: origin.y,
      onStart: runOnStart,
      onStartParams: [nextExpense],
      scale: 1,
      duration: 0.7,
      transformOrigin: "50% 50%",
      ease: "elastic.out(1, 0.3)",
      delay: 0.3,
      onComplete() {
        console.log("Drop in category animation Complete");
      },
    },
  );
}

// The animation is split into 2 parts:
// 1) the old expense amount is dropped off into the category.
// 2) the new expense amount appears in the wheel center.
//
// IN: nextExpense: the next expense to sort.
//                  null, if we have just sorted the last expense.
export function animateExpenseDropInCategory(nextExpense, setCenter) {
  gsap.to("#expense", {
    scale: 0,
    duration: 0.2,
    transformOrigin: "50% 50%",
    ease: "M0,0 C0.266,0.412 0.464,0.371 0.584,0.398 0.638,0.41 1,0.46 1,1 ",
    onComplete() {
      // When the last expense has been sorted, don't run the 2nd animation.
      if (nextExpense) {
        animateExpenseReturnToWheelCenter(nextExpense, setCenter);
      }
    },
  });
}
