// for currencies that have 2 decimals
// IN: val: string of some decimal number. number type ok too.
// 1.4 => 1.40
// 1.123 => 1.12
// 1.129 => 1.13 (rounds, not truncates)
// "" or null => 0
// returns string of the same number, but with exactly 2 decimal places
export function twoDecimals(val) {
  if (!val) return 0;
  return String(parseFloat(val).toFixed(2));
}
