import styled from "styled-components";

export const MARGIN_LEFT = "35px";

export const MainContainer = styled.div`
  background: ${(props) =>
    props.gradient
      ? props.gradient
      : `linear-gradient(180deg, #d9f7bd 0%, #adffb6 39.91%)`};
  width: 100%;
  height: 100%;
  // height: 100svh;
  display: flex;
  flex-direction: column;
`;

export const TableHolder = styled.div`
  background: #ffffff;
  width: 100%;
  border-radius: 36px 36px 0 0;
  display: flex;
  flex-direction: column;
`;

export const TableElementsContainer = styled.div`
  background: #f0f0f0;
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  margin-bottom: 10px;
  padding-bottom: ${(props) => (props.bottomPad ? props.bottomPad : 80)}px;
`;

export const Table = styled.div`
  background: #f0f0f0;
  width: 100%;
  border-radius: 36px 36px 0 0;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
`;

export const TitleText = styled.p`
  font-size: ${(props) => (props.fontSize ? props.fontSize : "20px")};
  line-height: 33px;
  margin-left: ${MARGIN_LEFT};
  margin-top: 15px;
  margin-bottom: 15px;
`;

export const ContentBackground = styled.div`
  background: #ffffff;
  flex: 1;
  padding: 20px;
`;

export const TopRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  font-size: 28px;
  fontweight: 700;
  textalign: center;
  padding-right: 40px;
  margin-left: 10%;
`;

export const ContentHolder = styled.div`
  background: #ffffff;
  width: 100%;
  border-radius: 36px 36px 0 0;
  box-shadow: 0px -4px 12px 0px #00000014;
  display: flex;
  flex-direction: column;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 30px;
  flex: 1;
  margin-top: 20px;
  overflow: auto;
  padding-bottom: 80px;
`;
