import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { ReactComponent as PlusCircle } from "../../../assets/img/Other/PlusCircle.svg";
import { ReactComponent as MinusCircle } from "../../../assets/img/Other/MinusCircle.svg";
//import { ReactComponent as Warning } from "../../../assets/img/Other/Warning.svg";  // exclamation mark !
import { InfoCircleOutlined } from "@ant-design/icons";
import { useUser } from "../../../state/UserProvider";
import SVGHeader from "./SVGHeader";

export default function ShortContent({
  dateString,
  category,
  budgetInfo,
  unsortedAmount,
  basicView = false,
  openTallDrawer,
}) {
  const { currentUser } = useUser();
  const mainCardRef = useRef(null);
  const [clickableAreaMaxY, setClickableAreaMaxY] = useState(0);
  useEffect(() => {
    setClickableAreaMaxY(calcClickableAreaMaxY);
  }, []);

  const calcClickableAreaMaxY = () => {
    let maxY = window.innerHeight;
    if (mainCardRef.current) {
      const rect = mainCardRef.current.getBoundingClientRect();
      maxY = rect.bottom + window.scrollY + 25;
    }
    return maxY;
  };

  const handleClick = (event) => {
    let mouseY = event.clientY;

    // if click on the top part of the drawer,
    // (ie any area above the BottomMessage component)
    if (mouseY < clickableAreaMaxY) openTallDrawer();
  };

  if (basicView) {
    return (
      <MainContainer>
        <BottomMessage>
          <WarningHolder>
            <Warning />
            <p className="warning">
              You have {unsortedAmount} expenses to sort.
            </p>
          </WarningHolder>
        </BottomMessage>
      </MainContainer>
    );
  }

  const headerLabel = category;

  return (
    <MainContainer onClick={handleClick}>
      <HeaderInfo>
        <SVGHeader headerLabel={headerLabel} />
        <DateText>{dateString}</DateText>
      </HeaderInfo>

      <MainCard budgetInfo={budgetInfo} ref={mainCardRef} />
      {unsortedAmount > 0 ? (
        <BottomMessage>
          <WarningHolder>
            <Warning />
            <p className="warning">
              You have {unsortedAmount} expenses to sort.
            </p>
          </WarningHolder>
        </BottomMessage>
      ) : (
        <BottomMessage>
          <p className="green">Keep it up, {currentUser.first_name}!</p>
          <p className="bottom">
            You're on track to meet your MoneyFlow goals this month.
          </p>
        </BottomMessage>
      )}
    </MainContainer>
  );
}

export function ShortContentForInitialExpenseReview({ unsortedAmount }) {
  return (
    <MainContainerExpenseReview>
      <BottomMessage>
        <WarningHolder>
          <Warning />
          <p className="warning">You have {unsortedAmount} expenses to sort.</p>
        </WarningHolder>
      </BottomMessage>
      <BottomMessage>
        <p className="bottom">
          For any expenses you are not sure how to categorize, you can put them
          into the "Other" category for now. You can always recategorize any
          expense later.
        </p>
      </BottomMessage>
    </MainContainerExpenseReview>
  );
}

export const MainCard = React.forwardRef((props, ref) => {
  let budgetInfo = props.budgetInfo;
  const remaining = budgetInfo ? budgetInfo.remaining : "Loading";
  const planned = budgetInfo ? budgetInfo.budgetAmount : "Loading";
  const spent = budgetInfo ? budgetInfo.actualSpend : "Loading";
  return (
    <InfoMain negative={remaining < 0} ref={ref}>
      <InfoLeft>
        <InfoLeftRow>
          <PlusCircle />
          <div>
            <p className="label">Planned</p>
            <p id="budget_number" className="amount">
              {"$" + planned}
            </p>
          </div>
        </InfoLeftRow>
        <InfoLeftRow>
          <MinusCircle />
          <div>
            <p className="label">Spent</p>
            <p id="spent_number" className="amount">
              {(spent < 0 ? "-" : "") + "$" + Math.abs(spent)}
            </p>
          </div>
        </InfoLeftRow>
      </InfoLeft>
      <InfoRight negative={remaining < 0}>
        <p className="label">Remaining</p>
        <p id="remaining_number" className="amount">
          {(remaining < 0 ? "-" : "") + "$" + Math.abs(remaining)}
        </p>
      </InfoRight>
    </InfoMain>
  );
});

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 94%;
  margin-left: 3%;
  overflow: auto;
  padding-bottom: 20px;
  @media (max-height: 750) {
    //this threshold and padding amounts might need to be adjusted
    padding-bottom: 450px;
  }
`;

const MainContainerExpenseReview = styled(MainContainer)`
  //margin-top: 100px;
`;

const HeaderInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 85%;
`;

const DateText = styled.p`
  font-size: 18px;
  line-height: 25px;
  text-align: right;
  color: #9d9d9d;
`;

const InfoMain = styled.div`
  // background: linear-gradient(180deg, #e7f5d9 1.7%, #adffb6 160.55%);
  border: 1px solid ${(props) => (props.negative ? `#FF8A00` : `#25B764`)};
  box-shadow: 0px 2px 5px 0px #d4d4d499;
  min-height: 128px;
  min-width: 360px;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 0 20px;
`;

const InfoLeft = styled.div`
  width: 140px;
  height: 104px;
  padding: 5px 10px 5px 10px;
  border-radius: 8px;
  // background-color: #f3fff4cc;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const InfoLeftRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  p {
    margin: 0;
  }
  .label {
    font-size: 10px;
    font-weight: 300;
    line-height: 14px;
    color: #0b0d16;
  }
  .amount {
    font-size: 16px;
    font-weight: 400;
  }
`;

const InfoRight = styled.div`
  text-align: right;
  .label {
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    margin: 0;
  }
  .amount {
    color: ${(props) => (props.negative ? `#FF8A00` : `#25B764`)};
    font-weight: 500;
    font-size: 40px;
    margin: 0;
  }
`;

const BottomMessage = styled.div`
  width: 360px;
  height: 64px;
  box-shadow: 0px 2px 5px 0px #d4d4d499;
  border-radius: 12px;
  padding: 10px 20px 10px 20px;
  margin-top: 18px;

  p {
    margin: 0;
  }
  .green {
    font-size: 18px;
    line-height: 25px;
    color: #00af15;
  }
  .bottom {
    font-size: 12px;
    line-height: 17px;
    color: #919191;
  }
  .warning {
    font-weight: 700;
    font-size: 18px;
    text-align: center;
  }
`;

const WarningHolder = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  gap: 10px;
`;

const Warning = styled(InfoCircleOutlined)`
  font-size: 24px;
  /* color: #ff0000; */
`;
