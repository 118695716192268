import styled from "styled-components";
import { ReactComponent as cashIcon } from "../../../assets/img/GoalWheel/goal-cash-icon.svg";
import getIcontSVG from "../IconHelper";

export default function GoalEntry({ asset, editRow }) {
  let SVG = null;
  SVG = cashIcon;
  SVG = getIcontSVG(asset.goal_category_id);

  console.log(asset);

  const category = "TEST";
  const title = asset.name;
  const value = asset.value;
  const date = asset.acquisition_date;
  const notes = asset.note;
  return (
    <MainContainer
      data-cy={"Card"}
      onClick={() => {
        editRow();
      }}
    >
      <LeftSide>
        {SVG && (
          <Icon>
            <SVG aria-label={category} />
          </Icon>
        )}
        <LeftColumn>
          <p className="top-bottom">{date}</p>
          <p>{title}</p>

          <p className="top-bottom">{notes}</p>
        </LeftColumn>
      </LeftSide>

      <div data-cy={"card-amount"}>${value}</div>
    </MainContainer>
  );
}

export const TABLE_ENTRY_HEIGHT = 85;

const MainContainer = styled.div`
  width: 363px;
  height: ${TABLE_ENTRY_HEIGHT}px;
  min-height: 80px;
  border-radius: 18px;
  background-color: #ffffff;
  box-shadow: 0px 2px 8px 0px #d4d4d4cc;
  margin-top: 10px;
  padding-right: 15px;
  padding-left: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  color: black;
  font-size: 18px;
  p {
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

const LeftSide = styled.div`
  display: flex;
  align-items: center;
`;
const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 10px;
  .top-bottom {
    font-size: 12px;
    color: #b7b7b7;
    .darker {
      // font-size: 16px;
      color: black;
    }
  }
`;

const Icon = styled.svg`
  height: 30px;
  width: 30px;
  margin-right: 10px;
  margin-left: 10px;
`;
