import { useState, useEffect } from "react";
import { Form, Input } from "antd";
import styled from "styled-components";
const { TextArea } = Input;

const defaultRulesCoolFormTextArea = [
  {
    required: false,
    message: "This field is required.",
  },
  { max: 1000, message: "Can be at most 1000 characters." },
  {
    // disallows:
    // To simplify the initial coding and to avoid potential
    // HTML injection attacks or escaping issues,
    // disallow these special characters: < > \
    //
    // Spaces, tabs, newlines, and other whitespace are allowed.
    //
    // Note: in the pattern below, \ is escaped with \
    pattern: /^[^<>\\]*$/,
    message: "Can not include < > or \\.",
  },
  /*
  {
    // disallows:
    // To simplify the initial coding and to avoid potential
    // HTML injection attacks or escaping issues,
    // disallow these special characters: < > ' " \ /
    //
    // Spaces, tabs, newlines, and other whitespace are allowed.
    //
    // Note: in the pattern below, - [ ]  are escaped with \
    pattern: /^[a-zA-Z0-9~!@#$%^&*()\-_=+\[\]{}|;:?.,\s]+$/,
    message:
      "Can include letters, numbers, spaces, and most symbols, but not < > ' \" \\ /.",
  },
  */
];

export default function CoolFormTextArea({
  name,
  rules = defaultRulesCoolFormTextArea,
  placeholder,
  hasInitialValue = false, // true, if we have given the form input an initial value
  required = false,
}) {
  const [moveText, setMovetext] = useState(false);
  useEffect(() => {
    if (hasInitialValue) {
      setMovetext(true);
    }
  }, [hasInitialValue]);

  const handleTextAreaChange = (e) => {
    if (e.target.value) {
      setMovetext(true);
    } else {
      setMovetext(false);
    }
  };
  return (
    <MainContainer>
      <SmallText>{moveText ? placeholder : ""}</SmallText>
      <Form.Item name={name} rules={rules}>
        <StyleTextArea
          onChange={handleTextAreaChange}
          placeholder={placeholder}
          showCount
          maxLength={1000}
        />
      </Form.Item>
    </MainContainer>
  );
}
const MainContainer = styled.div`
  position: relative;
  margin-top: 35px;
`;
const SmallText = styled.div`
  position: absolute;
  top: -10px;
  left: 10px;
  z-index: 10;
  background-color: white;
  flex-grow: 0;
  display: inline-block;
  padding: 0 4px;
  color: #68cd74;
  font-size: 0.75rem;
  max-height: 21px;
`;
const StyleTextArea = styled(TextArea)`
  &:focus {
    border-color: #68cd74 !important;
  }
  border-color: #d9f7bd !important;
  height: 120px;
  border-radius: 12px;
  resize: "none";
`;
