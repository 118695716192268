import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import backend from "../../functions/backend";
import { MoneyFlowHeaderNoMenu } from "../../components/PageComponents/MoneyFlowHeader";
import GreenGradientBackground from "../../components/UI/GreenGradientBackground";
import MoneyFlowFooter from "../../components/PageComponents/MoneyFlowFooter";
import { MainContainer } from "../../styles/styledLoginComponents";
import { Button } from "antd";
import "../../styles/IPadLook.css";
import styled from "styled-components";

export default function VerifyEmail() {
  const navigate = useNavigate();
  const [feedbackText, setFeedbackText] = useState();
  const [showLoginButton, setShowLoginButton] = useState(false);
  const [showRegisterButton, setShowRegisterButton] = useState(false);

  async function activation() {
    var values = {};

    var temparray = window.location.pathname.split("/");
    values["token"] = temparray[temparray.length - 1];
    var res = await backend.patch("/v1/user/verify_email", values);
    if (!res.success) {
      setFeedbackText(
        res.message +
          " We couldn't verify your email.  " +
          "Please try registering again.",
      );
      setShowRegisterButton(true);
      setShowLoginButton(false); // handle case of trying a 2nd token
    } else {
      setFeedbackText(
        "Thank you for verifying your email.  Please proceed to login.",
      );
      setShowLoginButton(true);
      setShowRegisterButton(false); // handle case of trying a 2nd token
      // navigate("/payment-registration", { replace: true });
    }
  }

  useEffect(() => {
    activation();
  }, []);

  return (
    <div className="screen">
      <MoneyFlowHeaderNoMenu />
      <MainContainer justifyContent={"flex-start"}>
        <GreenGradientBackground bottom={true} />
        <h1 style={{ paddingTop: "1em" }}>
          {showRegisterButton
            ? "Registration email invalid"
            : "Confirming registration email"}
        </h1>
        <div style={{ textAlign: "center" }}>
          <p style={{ marginBottom: "2em" }}>{feedbackText}</p>
          {showLoginButton && (
            <Button
              type="primary"
              onClick={() => navigate("/login", { replace: true })}
            >
              Go to Login
            </Button>
          )}
          {showRegisterButton && (
            <Button
              type="primary"
              onClick={() => navigate("/register", { replace: true })}
            >
              Go to Registration
            </Button>
          )}
        </div>
        <div
          style={{
            marginBottom: "1em",
            textAlign: "center",
            marginTop: "auto",
          }}
        >
          <MoneyFlowFooter />
        </div>
      </MainContainer>
    </div>
  );
}
