import { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import TableEntry from "./TableEntry";
import { LeftOutlined, UpOutlined, CloseOutlined } from "@ant-design/icons";
import { Form } from "antd";
import { categoryNames } from "./IconHelper";
import ExpenseEditform from "./ExpenseEditForm";

import {
  GreyTextEntry,
  GreyDropDown,
  SplitButton,
  SplitEntry,
  CreateSplitButton,
  AntGreyTextEntry,
  AntSplitEntry,
  IconAndText,
} from "../../../components/DrawerComponents/splitComponents";

export default function ExpenseDetails({
  back,
  entry,
  form,
  onFinish,
  extraInfo,
}) {
  const [doSplit, setDoSplit] = useState(false);
  const [editInfo, setEditInfo] = useState(false);
  const contentHolderRef = useRef(null);
  useEffect(() => {
    if (contentHolderRef.current && (doSplit || editInfo)) {
      contentHolderRef.current.scrollTop =
        contentHolderRef.current.scrollHeight;
    }
  }, [doSplit, editInfo]);
  const closeModal = () => {
    console.log("ExpenseDetails close modal called");
  };
  entry.posted_date = entry.date;
  return entry ? (
    <MainContainer>
      <BackButton
        data-cy="backBtn"
        onClick={() => {
          back();
          setDoSplit(false);
        }}
      >
        <LeftOutlined /> Back
      </BackButton>
      <TopRow>Expense Details</TopRow>
      <ContentHolder ref={contentHolderRef}>
        <FormContainer>
          <ExpenseEditform
            form={form}
            onFinish={onFinish}
            closeModal={closeModal}
            expenseInfo={entry}
            maxSplit={entry.amount}
            doSplit={doSplit}
            setDoSplit={setDoSplit}
            editInfo={editInfo}
            setEditInfo={setEditInfo}
            name="editExpense"
          />
        </FormContainer>
      </ContentHolder>
    </MainContainer>
  ) : (
    <div> Error setting expense </div>
  );
}

const MainContainer = styled.div`
  background: linear-gradient(
    0deg,
    rgba(255, 222, 51, 0.8) 54.23%,
    rgba(255, 250, 138, 0.8) 81.69%,
    rgba(255, 255, 151, 0.8) 100%
  );
  width: 100%;
  height: 100%;
  // height: 100svh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: -20px;
  padding-top: 40px;
`;
const TopRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  font-size: 28px;
  fontweight: 700;
  textalign: center;
  padding-right: 40px;
`;

const BackButton = styled.button`
  border-radius: 21px;
  width: 80px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 2px 8px 0px #ffc83abf;
  padding: 6px;
  border: none;
  margin-right: auto;
  margin-left: 10%;
`;

const ContentHolder = styled.div`
  background: #ffffff;
  width: 100%;
  border-radius: 36px 36px 0 0;
  box-shadow: 0px -4px 12px 0px #00000014;
  display: flex;
  flex-direction: column;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 30px;
  flex: 1;
  margin-top: 20px;
  overflow: auto;
  padding-bottom: 80px;
`;

const FormContainer = styled.div`
  box-shadow: 0px 2px 10px 0px #0b0d1626;
  border-radius: 22px;
  padding: 10px 15px 10px 15px;
`;

const SplitExpenseHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 20px;
  line-height: 27px;
`;

// {doSplit && (
//   <SplitExpenseHeader>
//     Split Expense <CloseOutlined onClick={() => setDoSplit(false)} />
//   </SplitExpenseHeader>
// )}

// <Form
//   form={form}
//   name="editExpense"
//   onFinish={onFinish}
//   onFinishFailed={() => {
//     back();
//   }}
// >
//   <AntGreyTextEntry
//     label="Expense Label"
//     name="label"
//   ></AntGreyTextEntry>
//   <AntGreyTextEntry label="Notes" name="note"></AntGreyTextEntry>
//   {
//     <AntSplitEntry
//       total={entry.amount}
//       name="splitAmount"
//       rules={[
//         {
//           required: true,
//           message: "The split amount is required.",
//         },
//         {
//           message:
//             "please enter a number between 0.01 - " +
//             entry.amount +
//             " dollars and cents",
//           validator: (_, value) => {
//             if (
//               parseFloat(value) >= 0.01 &&
//               parseFloat(value) <= entry.amount
//             ) {
//               return Promise.resolve();
//             } else {
//               return Promise.reject(
//                 "please enter a number between 0.01 - " +
//                   entry.amount +
//                   " dollars and cents",
//               );
//             }
//           },
//         },
//       ]}
//     ></AntSplitEntry>
//   }
//   <Form.Item name="category" initialValue={undefined}>
//     <GreyDropDown
//       category={category}
//       setCategory={(value) => {
//         setCategory(value);
//         form.setFieldsValue({ category: value });
//       }}
//     />
//   </Form.Item>

//   {!doSplit && (
//     <SplitButton
//       onClick={() => {
//         setDoSplit(true);
//       }}
//     >
//       Split
//     </SplitButton>
//   )}

//   <Form.Item name="submit">
//     <CreateSplitButton
//       form={"editExpense"}
//       key="submit"
//       htmlType="submit"
//     >
//       {!doSplit ? "Save Changes" : "Create Split"}
//     </CreateSplitButton>
//   </Form.Item>
// </Form>
