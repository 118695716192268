import styled from "styled-components";
import { useState, useEffect } from "react";
import { DownOutlined } from "@ant-design/icons";
import { Form } from "antd";

import SVGHeader from "./SVGHeader";
import PageSelector from "./PageSelector";
import TableHeader from "./TableHeader";
import TableEntry, { TABLE_ENTRY_HEIGHT } from "../NewUIHelpers/TableEntry";
import ExpenseDetails from "./ExpenseDetails";
import PlanContent from "./PlanContent";
import { MainCard } from "./ShortContent";

import useExpenseData from "../../ActiveTracker/useExpenseData";
import { useCheckResponseFail } from "../../../hooks/useCheckResponseFail";

import { getLastNumMonthsFromDateQueryString } from "../../../functions/timeDate";
import backend from "../../../functions/backend";

const MARGIN_LEFT = "35px";

export default function TallContent({
  editDate,
  dateString,
  category,
  tableData,
  onFinish,
  setEditID,
  budgetInfo,
  switchCategory,
  onFinishPlanned,
  viewMonth,
  viewYear,
  openShortDrawer,
}) {
  const headerLabel = category;

  const [tab, setTab] = useState("Spending");

  const [showExpenseDetails, setShowExpenseDetails] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [filteredData, setFilteredData] = useState(tableData);
  const [form] = Form.useForm();
  useEffect(() => {
    // update filteredData, when tableData changes due to date changer
    setFilteredData(tableData);
  }, [tableData]);
  console.log("tableData", tableData);
  const searchFunction = (searchTerm) => {
    const filtered = tableData.filter((entry) => {
      return entry.description.toLowerCase().includes(searchTerm.toLowerCase());
    });
    setFilteredData(filtered);
  };
  const createEntry = (entry) => {
    return (
      <TableEntry
        key={entry.id}
        date={entry.date}
        amount={entry.amount}
        originalAmount={entry.original_amount}
        counterParty={entry.counterparty}
        description={entry.description}
        // category={category === "All" ? entry.category : null}
        category={entry.isIncome ? "Income" : entry.category}
        isIncome={entry.isIncome}
        editRow={() => {
          setShowExpenseDetails(true);
          setSelectedEntry(entry);
          console.log("1123");
          console.log(entry.id);
          console.log(entry);
          setEditID(entry.id);

          const values = {
            splitAmount: entry.amount,
            label: entry.label !== null ? entry.label : "",
            note: entry.note !== null ? entry.note : "",
            category: entry.category_id,
          };
          form.setFieldsValue(values);
        }}
        id={entry.id}
      />
    );
  };
  const tableEntries = filteredData.map((entry) => createEntry(entry));
  const [incomeData, setIncomeData] = useState([]);
  const checkResponseFail = useCheckResponseFail();
  const { formatForTable } = useExpenseData();
  useEffect(() => {
    const fetchData = async () => {
      console.log("getCurrentCategoryExpenses for category: Income");
      let query =
        "/v1/tx/transaction?posted_date=" +
        getLastNumMonthsFromDateQueryString(6, viewMonth, viewYear) +
        "&_sort_by=posted_date&_sort_order=desc";

      query += "&tx_category_id=" + 101;

      console.log("query: " + query);

      const response = await backend.get(query);

      if (
        checkResponseFail(
          response,
          "Failed to GET current category transactions:",
        )
      ) {
        return;
      }

      console.log("Got " + response.items.length + " income transactions.");

      const tableData = formatForTable(response.items, true);

      setIncomeData(tableData.map((entry) => createEntry(entry)));
    };
    fetchData();
  }, [viewYear, viewMonth]);

  const getIncomeEntries = () => {
    return tableEntries.filter((entry) => entry.props.isIncome);
  };

  const handleClick = (event) => {
    openShortDrawer();
  };

  return showExpenseDetails ? (
    <ExpenseDetails
      back={() => {
        setShowExpenseDetails(false);
      }}
      entry={selectedEntry}
      form={form}
      onFinish={onFinish}
    />
  ) : (
    <MainContainer>
      {/* <h1> Tall Content </h1> */}
      {/* header and date section */}

      <div style={{ marginLeft: MARGIN_LEFT }} onClick={handleClick}>
        <DateSelector
          data-cy="tallDrawerDateSelect"
          onClick={(event) => {
            editDate();
            event.stopPropagation();
          }}
        >
          {dateString}
          <DownOutlined style={{ marginLeft: "8px" }} />
        </DateSelector>
        <SVGHeader
          headerLabel={headerLabel}
          fontSize="28px"
          category={category}
          switchCategory={switchCategory}
        />
      </div>
      {tab === "Planned" &&
        (category !== "All" ? (
          <>
            <TableHolder>
              <PageSelector
                showPlanned={category !== "All"}
                selectedIndex={0}
                setTab={setTab}
              />
              <TitleText>Your Plan</TitleText>
            </TableHolder>
            <PlanContent
              budgetInfo={budgetInfo}
              changePlanAmount={onFinishPlanned}
              category={category}
              viewMonth={viewMonth}
              viewYear={viewYear}
            />
          </>
        ) : (
          //income tab
          <>
            <TableHolder>
              <PageSelector
                showPlanned={category !== "All"}
                selectedIndex={0}
                setTab={setTab}
              />
              <TitleText>All Deposits</TitleText>
              <Table>
                <TableHeader searchFunction={searchFunction} />
              </Table>
            </TableHolder>
            <TableElementsContainer>
              {/* {getIncomeEntries()} */}
              {incomeData}
            </TableElementsContainer>
          </>
        ))}
      {tab === "Spending" && (
        <>
          <TableHolder>
            <PageSelector
              showPlanned={category !== "All"}
              selectedIndex={1}
              setTab={setTab}
            />
            <TitleText>Current Spending</TitleText>
            <ProgressBar
              spent={budgetInfo.actualSpend}
              total={budgetInfo.budgetAmount}
              remaining={budgetInfo.remaining}
            ></ProgressBar>
            <TitleText>All Expenses</TitleText>
            <Table>
              <TableHeader searchFunction={searchFunction} />
            </Table>
          </TableHolder>

          <TableElementsContainer>{tableEntries}</TableElementsContainer>
        </>
      )}
      {tab === "Overview" && (
        <>
          <TableHolder>
            <PageSelector
              showPlanned={category !== "All"}
              selectedIndex={2}
              setTab={setTab}
            />
            <TitleText></TitleText>
          </TableHolder>
          <ContentBackground>
            <MainCard budgetInfo={budgetInfo} />
          </ContentBackground>
        </>
      )}
    </MainContainer>
  );
}

const MainContainer = styled.div`
  background: linear-gradient(180deg, #d9f7bd 0%, #adffb6 39.91%);
  width: 100%;
  height: 100%;
  // height: 100svh;
  display: flex;
  flex-direction: column;
`;

//might need to change this to its own component when we add date selecting fucntionality
const DateSelector = styled.button`
  box-shadow: 0px 4px 4px 0px #d4d4d466;
  background: #f3fff4cc;
  border-radius: 20px;
  border: none;
  height: 40px;
  padding: 10px 20px 10px 20px;

  align-self: flex-start;
`;

const TableHolder = styled.div`
  background: #ffffff;
  width: 100%;
  border-radius: 36px 36px 0 0;
  display: flex;
  flex-direction: column;
`;

const TitleText = styled.p`
  font-size: ${(props) => (props.fontSize ? props.fontSize : "20px")};
  line-height: 33px;
  margin-left: ${MARGIN_LEFT};
  margin-top: 15px;
  margin-bottom: 15px;
`;

const Table = styled.div`
  background: #f0f0f0;
  width: 100%;
  border-radius: 36px 36px 0 0;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
`;

const TableElementsContainer = styled.div`
  background: #f0f0f0;
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  margin-bottom: 10px;
  padding-bottom: ${TABLE_ENTRY_HEIGHT}px;
`;

const ContentBackground = styled.div`
  background: #ffffff;
  flex: 1;
  padding: 20px;
`;

const ProgressBar = ({ spent, total, remaining }) => {
  const currentDate = new Date();
  const lastDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0,
  );
  const days = lastDayOfMonth.getDate() - currentDate.getDate();
  const percent = Math.floor((spent / total) * 100);
  console.log("percent", percent);
  return (
    <ProgressBarContainer>
      <p>
        <span className="orange">
          $<span data-cy="spentAmount">{spent}</span>
        </span>{" "}
        spent of $<span data-cy="totalAmount">{total}</span>
      </p>
      <ProgressBarBar className="outer">
        <ProgressBarBar className="inner" percent={percent} />
      </ProgressBarBar>
      <p className="lower">
        <span className="green">
          $<span data-cy="remainingAmount">{remaining}</span>
        </span>{" "}
        remaining for the next {days} days
      </p>
    </ProgressBarContainer>
  );
};

const ProgressBarContainer = styled.div`
  border-radius: 16px;
  box-shadow: 0px 2px 10px 0px #0b0d1626;
  background: white;
  font-size: 10px;
  color: #0b0d16bf;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding: 0 20px 0 20px;

  .orange {
    color: #fd6a2e;
    font-size: 24px;
  }
  .green {
    color: #00af15;
    font-size: 12px;
  }
  .lower {
    text-align: right;
  }
`;

const ProgressBarBar = styled.div`
  border-radius: 20px;

  &.inner {
    background: #fd6a2e;
    position: absolute;
    left: 0px;
    top: 0px;
    height: 98%;
    width: ${(props) => (props.percent ? props.percent : 0)}%;
  }
  &.outer {
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    background: #ebedf0;
    position: relative;
    height: 10px;
  }
`;
