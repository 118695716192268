import categoryData from "../CFTSetup/Forms/Data";
import { ReactComponent as wheelIcon } from "../../assets/img/Nav/wheels-icon-colored.svg";
import { ReactComponent as incomeIcon } from "../../assets/img/Other/income-icon.svg";

import { ReactComponent as SinglePerson } from "../../assets/img/GoalWheel/NewIcons/person.svg";
import { ReactComponent as Home } from "../../assets/img/GoalWheel/NewIcons/house.svg";
import { ReactComponent as Car } from "../../assets/img/GoalWheel/NewIcons/car.svg";
import { ReactComponent as Hat } from "../../assets/img/GoalWheel/NewIcons/hat.svg";
import { ReactComponent as Money } from "../../assets/img/GoalWheel/NewIcons/cash.svg";
import { ReactComponent as Health } from "../../assets/img/GoalWheel/NewIcons/heart.svg";
import { ReactComponent as Entertainment } from "../../assets/img/GoalWheel/NewIcons/masks.svg";
import { ReactComponent as MoneyFlower } from "../../assets/img/GoalWheel/NewIcons/flower.svg";

//need to be rewritten with the correct icons

//this function will take an index or a string and return the appropriate icon
const getIcontSVG = (iconName) => {
  switch (iconName) {
    case 0:
      return wheelIcon;
    case 1:
      return SinglePerson;
    case 2:
      return MoneyFlower;
    case 3:
      return Home;
    case 4:
      return Entertainment;
    case 5:
      return Health;
    case 6:
      return Car;
    case 7:
      return Hat;
    case 8:
      return Money;
    case "Income":
      return incomeIcon;
    default:
      return null;
  }
};

export default getIcontSVG;
