import styled from "styled-components";

export default function SVGHeader({ headerLabel, fontSize = "24px", SVG }) {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <SVG height={fontSize} />
      <HeaderText fontSize={fontSize}>{headerLabel}</HeaderText>
    </div>
  );
}

const HeaderText = styled.p`
  font-size: ${(props) => (props.fontSize ? props.fontSize : "24px")};
  line-height: 33px;
  margin-right: auto;
  margin-left: 10px;
`;
