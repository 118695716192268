import React, { useEffect, useRef, forwardRef, useState } from "react";
import gsap from "gsap";
import { Draggable } from "gsap/all";
import { origin, animateExpenseDropInCategory } from "./TrackerAnimations";

import { daysLeftInMonthString } from "../../functions/timeDate";

import PlacedSVG from "./PlacedSVG";
import styled from "styled-components";
import { Tooltip } from "antd";
import { ReactComponent as EditIcon } from "../../assets/img/Other/edit-icon.svg";
//How to import
/* <Tracker dropExpense={callbackDropExpense} expense={json} config={json} > */

const Tracker = forwardRef(function Tracker(props, ref) {
  //if we would like to use the placeholder expense, then change debug to true
  const debug = false;

  gsap.registerPlugin(Draggable);

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const ref7 = useRef(null);
  const ref8 = useRef(null);
  const ref9 = useRef(null);

  // share the refs from this component with the parent component
  // so that the Tour can use them
  React.useImperativeHandle(ref, () => ({
    /* for MoneyWheel tour */
    ref1,
    ref2,
    ref3,
    /* for CreditWheel tour */
    ref4,
    ref5,
    ref6,
    /* for GoalsWheel tour */
    ref7,
    ref8,
    ref9,
  }));
  /* TODO: For now, ref4 - ref9 are assigned to random wheel sections below.
     Will need to move the refs to the places we want to highlight in the tour. */

  const WHEEL_RADIUS = 120;

  const editfunction = props.EditFunction;
  const showNumbers = props.showNumbers;
  const categoryFigures = props.categoryFigures;

  // categoryFigures is the text for each wedge and the center in the wheel.
  // For example, ActiveTracker sets categoryFigures to be the remainingBudget.
  // ExpenseReview sets categoryFigures = {}

  const switchCategory = props.switchCategory;

  // draggableExpense is the 1 expense, which goes in the draggable circle.
  // If there are no unsorted expenses, then draggableExpense is undefined or null.
  const draggableExpense = props.draggableExpense;

  // nextExpense is the expense after the draggableExpense.
  // This is needed after dropping the current draggableExpense into a category,
  // so we can immediately display the next expense in the draggable circle,
  // at the exact right time during the animation when the expense appears
  // back in the center of the wheel.
  // We won't have to wait for an API call to complete and update expenseData.
  const nextExpense = props.nextExpense;

  // the prop.config will overright this, it is required to pass config in, keeping this here
  // for reference
  // WARNING, modifying this config will not do anything, modify the props config (this is just for reference)
  let config = {
    family: {
      married: true,
      kids: 1,
      kidAgeRanges: [0, 2, 3, 5],
    },
    transportation: "public",
    draggable: true,
    clickable: true,
    showText: false,
    color: "#5c5c5c",
    bgColor: "#EBF2FA",
    borderColor: "#333",
    borderColorActive: "#333",
    vectorColor: "#E7EFF9",
    expenseForeColor: "#333",
    expenseBackgroundColor: "#E7EFF9",
    iconColorInActive: "#333",
    iconColorActive: "#edebf3",
    strokeWidth: 10,
    lastCategoryDisplayed: 4,
  };
  //console.log("CONFIG", props.config);
  config = { ...props.config }; //Override Defaults
  config.bgColor = config.bgColor || "#EBF2FA";
  config.color = config.color || "#5c5c5c";
  config.borderColor = config.borderColor || "#70BF37";
  config.borderColorActive = config.borderColorActive || "#70BF37";
  config.vectorColor = config.vectorColor || "#CBFAAC";
  config.expenseForeColor = config.expenseForeColor || "#333";
  config.expenseBackgroundColor = config.expenseBackgroundColor || "#E7EFF9";
  config.iconColorInActive = config.iconColorInActive || "#333";
  config.iconColorActive = config.iconColorActive || "#edebf3";
  config.strokeWidth = config.strokeWidth || 10;
  /*
  // Could set the different category icon colors here.
  // However, currently the colors are set in the svg itself.
  config.personalFill = "#0E95D4";
  config.groceryFill = "#F89622";
  config.homeFill = "#D2BB18";
  config.entertainmentFill = "#43BBB4";
  config.healthFill = "#EC6F63";
  config.transportFill = "#F68846";
  config.utilitiesFill = "#E836DB";
  config.otherFill = "#70BF37";
  */

  //Default categories for tracker - these reference SVG items
  const items = [
    "Personal",
    "Home",
    "Transport",
    "Utility",
    "Other",
    "Health",
    "Entertainment",
    "Food",
  ];

  //used to set the correct values for the next draggable center expense
  function setCenter(data) {
    let negativeSignElement = document.getElementById(
      "txt-amount-inner-negative-sign",
    );
    // lengths: (&hyphen; = "-") < (&ndash; = &minus;) < &mdash;
    //negativeSignElement.textContent = data.amount < 0 ? "-" : ""; // appears like &minus;
    negativeSignElement.innerHTML = data.amount < 0 ? "&hyphen;" : "";

    let expenseAmount = document.getElementById("txt-amount-inner");
    expenseAmount.textContent = `${Math.abs(Math.round(data.amount))}`;

    let negativeSign2 = data.original_amount < 0 ? "&hyphen" : "";
    let original = document.getElementById("txt-amount-original");
    original.innerHTML =
      data.original_amount &&
      parseFloat(data.original_amount) !== parseFloat(data.amount)
        ? `of ${negativeSign2}$${Math.abs(Math.round(data.original_amount))}`
        : "";

    //here we will split the title into two lines if it is long enough
    let expenseTitle1 = document.getElementById("txt-title-1");
    expenseTitle1.textContent = data.description.substring(0, 13);

    let expenseTitle2 = document.getElementById("txt-date");
    expenseTitle2.textContent = data.date;
    console.log(8876);
    console.log(data);
  }

  function getCenterValue() {
    return categoryFigures.center;
  }

  //When you have a draggable expense, this figures out if it's gone too far, outside the wheel.
  //Return false, if the object is still inside the radius, and return the correct coords if not.
  //typically usage would be to call this function and if it returns coords, set the object to those coords
  function getNewCoords(x, y, radius) {
    const distance = Math.sqrt(x * x + y * y);
    if (distance > radius) {
      let angle = Math.atan(y / x);
      if (x < 0) {
        angle += Math.PI;
      }
      const newX = radius * Math.cos(angle);
      const newY = radius * Math.sin(angle);

      return { x: newX, y: newY };
    }
    return false;
  }

  const [currentShape, setCurrentShape] = useState(null);
  function boldSection(id) {
    if (currentShape) {
      const section = document.getElementById(currentShape);
      gsap.to(section, {
        stroke: config.borderColor,
        strokeWidth: "0",
      });
    }
    setCurrentShape(id);
    if (id) {
      const section = document.getElementById(id);
      gsap.to(section, {
        stroke: config.borderColorActive,
        strokeWidth: "2",
      });
    }
  }

  useEffect(() => {
    // This shows the first item in the sorted-by-Date array
    if (draggableExpense?.amount && draggableExpense?.description) {
      setCenter(draggableExpense);
    }
  }, [draggableExpense]);

  // //Do things after we load our virtual Dom
  useEffect(() => {
    //next line is just for testing
    // setCenter({ description: "0123456789abcdefghijklmnopqrstuvqxyz", amount: 80 });

    const dropAreas = ["N", "NE", "E", "SE", "S", "SW", "W", "NW"];

    const dropVectors = [];
    const dropGroups = [];
    let GlobalOverlapThreshold = "70%";

    for (let i = 0; i <= 7; ++i) {
      dropVectors.push(document.getElementById(dropAreas[i] + "_Shape"));
      dropGroups.push(document.getElementById(dropAreas[i] + "_Group"));
    }
    if (!showNumbers) {
      for (let i = 0; i <= 7; ++i) {
        let txt = document.getElementById("txt-amount" + dropAreas[i] + "V");
        if (txt) {
          txt.setAttribute("visibility", "hidden");
        }
      }
    }

    //console.warn("dropGroups and dropVectors", dropGroups, dropVectors);

    /*
    gsap.to(dropVectors[7], {
      duration: 0.6,
      x: 10,
    });
    */
    function getParentID(domOBJ, id) {
      if (domOBJ.id == id) {
        return domOBJ;
      } else {
        if (document.body !== domOBJ) {
          return getParentID(domOBJ.parentNode, id);
        } else {
          return { id: "" };
        }
      }
    }
    Draggable.create("#expense", {
      type: "x,y",
      dragClickables: true,
      edgeResistance: 1,
      //this bounds the draggable expense, but we are handling that seperately with the getNewCoords function
      // bounds: "#donut",
      onPress() {
        //ENTIRE WHEEL INTERACTION EXCITEMENT ANIMATION
        // for (let i = 0; i <= 7; ++i) {
        //   gsap.to(dropGroups[i], {
        //     scale: 1,
        //     opacity: 0.3,
        //     duration: 3,
        //     transformOrigin: "50% 50%",
        //     ease: "elastic.out(1, 0.3)",
        //   })
        // }
      },
      onClick: (e) => {
        console.log("CLICKED", e.target.id);
        //we are removing the edit button, so we will apply the edit function if anything inside the container is clicked
        editfunction(draggableExpense.key, false);
      },

      onDragStart: function () {},
      onDrag: function (event) {
        const coords = getNewCoords(this.x, this.y, WHEEL_RADIUS);
        if (coords) {
          gsap.set("#expense", { x: coords.x, y: coords.y });
        }

        gsap.to("#expense", {
          opacity: 0.9,
          boxShadow: "0px 0px 20px 10px #888888",
        });

        //Highlight droppable selected category
        const overlapThreshold = GlobalOverlapThreshold;
        let collisions = 0;
        for (let i = 0; i <= 7; ++i) {
          if (
            this.hitTest(dropVectors[i], overlapThreshold) &&
            collisions === 0
          ) {
            gsap.to(dropVectors[i], {
              stroke: config.borderColorActive,
              strokeWidth: "2",
              duration: 0.6,
              yoyo: true,
              transformOrigin: "50% 50%",
              ease: "elastic.out(1, 0.3)",
              visibility: "visible",
            });
            gsap.to(dropGroups[i], {
              scale: 1,
              opacity: 1,
              duration: 1,
              transformOrigin: "50% 50%",
              ease: "elastic.out(1, 0.3)",
            });
            collisions++;
          } else {
            gsap.to(dropGroups[i], {
              scale: 1,
              opacity: 0.4, //change this to modify how much the other categories are dimmed
              duration: 1,
              transformOrigin: "50% 50%",
              ease: "elastic.out(1, 0.3)",
            });
            gsap.to(dropVectors[i], {
              stroke: config.borderColorActive,
              strokeWidth: "0",
              duration: 0.6,
              yoyo: true,
              transformOrigin: "50% 50%",
              ease: "elastic.out(1, 0.3)",
              visibility: "visible",
            });
          }
        }
        // FUN WHEEL DRAG ANIMATIONS

        // This works well in chrome but not well at all in safari or firefox, removing for the time being

        let overlapThresholdDrag = "5%";
        // let collisions = 0;
        //here we calculate the overlap threshold so that only one category is highlighted
        // collisions = 2; // just so we enter the loop at least once
        // let threshIncrement = 1;
        // while (collisions > 1) {
        //   collisions = 0;
        //   overlapThresholdDrag = `${5 * threshIncrement}%`;
        //   for (let i = 0; i <= 7; ++i) {
        //     if (this.hitTest(dropGroups[i], overlapThresholdDrag)) {
        //       collisions++;
        //     }
        //   }
        //   threshIncrement++;
        // }
        overlapThresholdDrag = GlobalOverlapThreshold;
        // GlobalOverlapThreshold = overlapThresholdDrag;
        // for (let i = 0; i <= 7; ++i) {
        //   if (this.hitTest(dropGroups[i], overlapThresholdDrag)) {
        //     gsap.to(dropGroups[i], {
        //       scale: 1,
        //       opacity: 1,
        //       duration: 1,
        //       transformOrigin: "50% 50%",
        //       ease: "elastic.out(1, 0.3)",
        //     });
        //     collisions++;
        //   } else {
        //     gsap.to(dropGroups[i], {
        //       scale: 1,
        //       opacity: 0.4, //change this to modify how much the other categories are dimmed
        //       duration: 1,
        //       transformOrigin: "50% 50%",
        //       ease: "elastic.out(1, 0.3)",
        //     });
        //   }
        // }
      },
      onDragEnd: function (event) {
        gsap.to("#expense", {
          opacity: 1,
          boxShadow: "0px 0px 20px 10px #888888",
        });
        for (let i = 0; i <= 7; ++i) {
          gsap.to(dropGroups[i], {
            scale: 1,
            opacity: 1,
            duration: 4,
            transformOrigin: "50% 50%",
          });
          gsap.to(dropVectors[i], {
            strokeWidth: "0",
          });
        }

        const overlapThreshold = GlobalOverlapThreshold;

        //First check to see what category we overlap with
        let highestOverlapThreshold = "5%";
        // let collisions = 0;
        //here we calculate the overlap threshold so that only one category is highlighted

        // let collisions = 2; // just so we enter the loop at least once
        // let threshIncrement = 1;
        // while (collisions > 1) {
        //   collisions = 0;
        //   highestOverlapThreshold = `${50 + 5 * threshIncrement}%`;
        //   for (let i = 0; i <= 7; ++i) {
        //     if (this.hitTest(dropVectors[i], highestOverlapThreshold)) {
        //       collisions++;
        //     }
        //   }
        //   threshIncrement++;
        // }

        // ^^ this stuff leads to misleading drops in safari and firefox
        highestOverlapThreshold = GlobalOverlapThreshold;
        let collisions = 0;
        for (let i = 0; i <= 7; ++i) {
          //This is the hitTest for each category in the wheel
          if (
            collisions === 0 &&
            this.hitTest(dropVectors[i], highestOverlapThreshold)
          ) {
            collisions += 1;
            console.log("DROPPED", items[i]);
            let currentItem = items[i];

            let object = dropVectors[i];

            let txt = document.getElementById(
              "txt-amount" + dropAreas[i] + "V",
            );
            if (txt) {
              gsap.to(txt, {
                strokeWidth: 1,
                duration: 0.6,
                fontSize: 20,
                onComplete() {
                  gsap.to(txt, {
                    strokeWidth: 0,
                    fontSize: 17,
                    duration: 0.3,
                  });
                },
              });
            }

            gsap.to(object, {
              scale: 1.05,
              stroke: config.borderColorActive,
              strokeWidth: "4",
              duration: 0.6,
              yoyo: true,
              transformOrigin: "50% 50%",
              ease: "elastic.out(1, 0.3)",
              visibility: "visible",
              onComplete() {
                //Callback of expense dropped
                try {
                  //                Current Item      Category
                  props.dropExpense(draggableExpense, currentItem);

                  // This is bad.  draggableExpense will still be the old value here.
                  // It's setting it back to the old value, when it's already
                  // been updated to the new value.
                  //setCenter(draggableExpense);
                } catch (e) {
                  console.error(
                    "No Callback function provided to component, this could be due to an empty expense list",
                  );
                }

                // animation of the category
                gsap.to(object, {
                  scale: 1,
                  stroke: config.borderColor,
                  strokeWidth: "7",
                  duration: 0.3,
                  transformOrigin: "50% 50%",
                  ease: "back.out(1, 0.3)",
                  onComplete() {
                    //resetAll();
                    gsap.to(object, {
                      strokeWidth: "0",
                      fill: config.vectorColor,
                      stroke: config.color,
                      //visibility: "hidden",
                    });
                  },
                });
              },
            });

            animateExpenseDropInCategory(nextExpense, setCenter);
            collisions++;
          }
        }

        if (collisions === 0) {
          console.log(origin);
          gsap.to("#expense", {
            x: origin.x,
            y: origin.y,
            duration: 0.3,
            onComplete() {
              console.log("Snap back to center");
              //resetAll();
            },
          });
        }
      },
    });
  });

  return (
    <div className="Tracker" id="donut">
      {/* <TrackerSVG config={config} /> */}
      <svg
        width="360"
        height="360"
        viewBox="38 38 390 390"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ overflow: "visible" }}
      >
        <g id="Wheel_Group" ref={ref1}>
          <g id="White_Ellipse_Background" filter="url(#filter0_d_5629_74)">
            <circle cx="233.502" cy="232" r="193.5" fill="white" />
          </g>
          <g
            id="NW_Group"
            onClick={() => {
              switchCategory("Food", 2);
              boldSection("NW_Shape");
            }}
          >
            <path
              id="NW_Shape"
              d="M163.605 73.0461C162.504 70.5116 159.552 69.3443 157.05 70.5174C120.604 87.6073 90.9477 116.467 72.8595 152.447C71.6192 154.914 72.7027 157.895 75.2031 159.066L155.473 196.673C157.974 197.844 160.937 196.758 162.261 194.334C170.046 180.079 181.948 168.497 196.406 161.109C198.863 159.853 200.032 156.923 198.932 154.392L163.605 73.0461Z"
              fill={config.vectorColor}
            />
            <g id="NW_Icon-Amount">
              <PlacedSVG
                svg={props.categoryIcons.NW_Icon}
                label={`${categoryFigures.Food}`}
                categoryLabel={props.categoryLabel[7]}
                textBelowPositionAngle={
                  props.Text_Below_Position_Angle_Map["NW"]
                }
                textAbovePositionAngle={
                  props.Text_Above_Position_Angle_Map["NW"]
                }
                groupRadius={props.Radius_Map["NW"]}
                iconSize={props.Size_Map["NW"]}
                text_id="txt-amountNWV"
                icon_id="iconFood"
                fontSize="15"
                fill={config.groceryFill}
                position="NW"
              />
            </g>
          </g>

          <g
            id="W_Group"
            ref={ref7}
            onClick={() => {
              switchCategory("Entertainment", 4);
              boldSection("W_Shape");
            }}
          >
            <path
              id="W_Shape"
              d="M70.7467 169.35C68.1774 168.338 65.2669 169.599 64.3267 172.195C50.6151 210.06 50.0246 251.438 62.6501 289.663C63.5167 292.287 66.393 293.63 68.9907 292.688L152.363 262.467C154.957 261.526 156.283 258.665 155.507 256.016C150.941 240.434 151.177 223.828 156.185 208.376C157.037 205.749 155.79 202.849 153.221 201.837L70.7467 169.35Z"
              fill={config.vectorColor}
            />
            <g id="W_Icon-Amount">
              <PlacedSVG
                svg={props.categoryIcons.W_Icon}
                label={`${categoryFigures.Entertainment}`}
                categoryLabel={props.categoryLabel[6]}
                textBelowPositionAngle={
                  props.Text_Below_Position_Angle_Map["W"]
                }
                textAbovePositionAngle={
                  props.Text_Above_Position_Angle_Map["W"]
                }
                groupRadius={props.Radius_Map["W"]}
                iconSize={props.Size_Map["W"]}
                text_id="txt-amountWV"
                icon_id="iconEnternainment"
                fontSize="15"
                fill={config.entertainmentFill}
                position="W"
              />
            </g>
          </g>

          <g
            id="SW_Group"
            ref={ref4}
            onClick={() => {
              switchCategory("Health", 5);
              boldSection("SW_Shape");
            }}
          >
            <path
              id="SW_Shape"
              d="M73.1507 302.66C70.6204 303.762 69.4548 306.709 70.6241 309.209C87.6798 345.67 116.503 375.33 152.449 393.41C154.916 394.651 157.897 393.567 159.069 391.066L196.703 310.714C197.874 308.214 196.788 305.251 194.365 303.927C180.127 296.147 168.563 284.247 161.188 269.789C159.933 267.328 156.999 266.158 154.465 267.261L73.1507 302.66Z"
              fill={config.vectorColor}
            />
            <g id="SW_Icon-Amount">
              <PlacedSVG
                svg={props.categoryIcons.SW_Icon}
                label={`${categoryFigures.Health}`}
                categoryLabel={props.categoryLabel[5]}
                textBelowPositionAngle={
                  props.Text_Below_Position_Angle_Map["SW"]
                }
                textAbovePositionAngle={
                  props.Text_Above_Position_Angle_Map["SW"]
                }
                groupRadius={props.Radius_Map["SW"]}
                iconSize={props.Size_Map["SW"]}
                text_id="txt-amountSWV"
                icon_id="iconHealth"
                fontSize="15"
                fill={config.healthFill}
                position="SW"
              />
            </g>
          </g>

          <g
            id="S_Group"
            ref={ref5}
            onClick={() => {
              switchCategory("Other", 8);
              boldSection("S_Shape");
            }}
          >
            <path
              id="S_Shape"
              d="M169.124 395.4C168.112 397.969 169.373 400.879 171.97 401.819C209.803 415.517 251.156 416.081 289.365 403.422C291.985 402.554 293.325 399.682 292.387 397.087L262.235 313.679C261.295 311.08 258.429 309.752 255.778 310.531C240.207 315.106 223.617 314.879 208.183 309.88C205.556 309.029 202.656 310.275 201.644 312.844L169.124 395.4Z"
              fill={config.vectorColor}
            />
            <g id="S_Icon-Amount">
              <PlacedSVG
                svg={props.categoryIcons.S_Icon}
                label={`${categoryFigures.Other}`}
                categoryLabel={props.categoryLabel[4]}
                textBelowPositionAngle={
                  props.Text_Below_Position_Angle_Map["S"]
                }
                textAbovePositionAngle={
                  props.Text_Above_Position_Angle_Map["S"]
                }
                groupRadius={props.Radius_Map["S"]}
                iconSize={props.Size_Map["S"]}
                text_id="txt-amountSV"
                icon_id="iconOther"
                fontSize="15"
                fill={config.otherFill}
                position="S"
              />
            </g>
          </g>

          <g
            id="SE_Group"
            ref={ref6}
            onClick={() => {
              switchCategory("Utility", 7);
              boldSection("SE_Shape");
            }}
          >
            <path
              id="SE_Shape"
              d="M302.567 393.023C303.667 395.557 306.619 396.724 309.121 395.551C345.567 378.461 375.224 349.601 393.312 313.622C394.552 311.155 393.469 308.174 390.968 307.003L310.698 269.396C308.198 268.224 305.234 269.311 303.91 271.735C296.125 285.99 284.223 297.572 269.765 304.96C267.308 306.216 266.14 309.146 267.239 311.677L302.567 393.023Z"
              fill={config.vectorColor}
            />
            <g id="SE_Icon-Amount">
              <PlacedSVG
                svg={props.categoryIcons.SE_Icon}
                label={`${categoryFigures.Utility}`}
                categoryLabel={props.categoryLabel[3]}
                textBelowPositionAngle={
                  props.Text_Below_Position_Angle_Map["SE"]
                }
                textAbovePositionAngle={
                  props.Text_Above_Position_Angle_Map["SE"]
                }
                groupRadius={props.Radius_Map["SE"]}
                iconSize={props.Size_Map["SE"]}
                text_id="txt-amountSEV"
                icon_id="iconUtility"
                fontSize="15"
                fill={config.utilitiesFill}
                position="SE"
              />
            </g>
          </g>
          <g
            id="E_Group"
            ref={ref8}
            onClick={() => {
              switchCategory("Transport", 6);
              boldSection("E_Shape");
            }}
          >
            <path
              id="E_Shape"
              d="M395.304 297.149C397.873 298.161 400.783 296.9 401.724 294.303C415.435 256.439 416.026 215.06 403.4 176.835C402.534 174.211 399.657 172.869 397.06 173.81L313.688 204.032C311.093 204.972 309.767 207.834 310.543 210.482C315.109 226.065 314.873 242.671 309.865 258.122C309.014 260.749 310.26 263.649 312.829 264.661L395.304 297.149Z"
              fill={config.vectorColor}
            />
            <g id="E_Icon-Amount">
              <PlacedSVG
                svg={props.categoryIcons.E_Icon}
                label={`${categoryFigures.Transport}`}
                categoryLabel={props.categoryLabel[2]}
                textBelowPositionAngle={
                  props.Text_Below_Position_Angle_Map["E"]
                }
                textAbovePositionAngle={
                  props.Text_Above_Position_Angle_Map["E"]
                }
                groupRadius={props.Radius_Map["E"]}
                iconSize={props.Size_Map["E"]}
                text_id="txt-amountEV"
                icon_id="iconTransport"
                fontSize="15"
                fill={config.transportFill}
                position="E"
              />
            </g>
          </g>

          <g
            id="NE_Group"
            ref={ref9}
            onClick={() => {
              switchCategory("Home", 3);
              boldSection("NE_Shape");
            }}
          >
            <path
              id="NE_Shape"
              d="M393.021 163.409C395.551 162.307 396.717 159.36 395.547 156.86C378.492 120.399 349.668 90.7384 313.722 72.6586C311.255 71.4177 308.274 72.5015 307.103 75.0024L269.468 155.355C268.297 157.855 269.384 160.818 271.807 162.142C286.044 169.922 297.608 181.821 304.983 196.28C306.239 198.741 309.173 199.911 311.706 198.808L393.021 163.409Z"
              fill={config.vectorColor}
            />
            <g id="NE_Icon-Amount">
              <PlacedSVG
                svg={props.categoryIcons.NE_Icon}
                label={`${categoryFigures.Home}`}
                categoryLabel={props.categoryLabel[1]}
                textBelowPositionAngle={
                  props.Text_Below_Position_Angle_Map["NE"]
                }
                textAbovePositionAngle={
                  props.Text_Above_Position_Angle_Map["NE"]
                }
                groupRadius={props.Radius_Map["NE"]}
                iconSize={props.Size_Map["NE"]}
                text_id="txt-amountNEV"
                icon_id="iconHome"
                fontSize="15"
                fill={config.homeFill}
                position="NE"
              />
            </g>
          </g>

          <g
            id="N_Group"
            ref={ref3}
            onClick={() => {
              switchCategory("Personal", 1);
              boldSection("N_Shape");
            }}
          >
            <path
              id="N_Shape"
              d="M297.039 70.384C298.051 67.8147 296.79 64.9042 294.194 63.9642C256.361 50.2671 215.008 49.7024 176.799 62.3612C174.179 63.2291 172.839 66.1011 173.777 68.6964L203.929 152.105C204.868 154.704 207.734 156.032 210.385 155.253C225.956 150.677 242.547 150.904 257.981 155.904C260.608 156.755 263.508 155.509 264.52 152.939L297.039 70.384Z"
              fill={config.vectorColor}
            />
            <g id="N_Icon-Amount">
              <PlacedSVG
                svg={props.categoryIcons.N_Icon}
                // label is the dollar amount
                label={`${categoryFigures.Personal}`}
                // categoryLabel is the text description for the category
                categoryLabel={props.categoryLabel[0]}
                textBelowPositionAngle={
                  props.Text_Below_Position_Angle_Map["N"]
                }
                textAbovePositionAngle={
                  props.Text_Above_Position_Angle_Map["N"]
                }
                groupRadius={props.Radius_Map["N"]}
                iconSize={props.Size_Map["N"]}
                text_id="txt-amountNV"
                icon_id="iconPersonal"
                fontSize="15"
                fill={config.personalFill}
                position="N"
              />
            </g>
          </g>
          <g
            id="Center_Text"
            onClick={() => {
              switchCategory("All", "All");
              boldSection(null);
              console.log("Switch category");
            }}
          >
            <circle
              id="Ellipse 2"
              cx="233"
              cy="233"
              r="72"
              // fill="#EFFEE6"
              fill={config.vectorColor}
              strokeWidth="4"
            />
            {!props.isExpenseReview && props.wheelType === "MoneyWheel" && (
              <g>
                <text
                  id="txt-amountSum"
                  x="233"
                  y="232"
                  width="60"
                  height="30"
                  fontSize={
                    Math.round(getCenterValue()) >= 100000 ? "2.3em" : "3em"
                  }
                  // fontWeight="bold"
                  // fill={config.expenseForeColor}
                  fill="#222f3a"
                  textAnchor="middle"
                  strokeWidth="2"
                >
                  ${Math.round(getCenterValue())}
                </text>
                <text
                  id="txt-amountDays"
                  x="233"
                  y="262"
                  width="60"
                  height="30"
                  fontSize="15"
                  // fill={config.expenseForeColor}
                  fill="#596064"
                  textAnchor="middle"
                  strokeWidth="0"
                >
                  {daysLeftInMonthString()}
                </text>
              </g>
            )}
          </g>
        </g>
        {/* draggable expense */}
        {(draggableExpense || debug) && (
          <g
            id="expense"
            ref={ref2}
            className="draggable"
            filter="url(#filter0_d_5629_74)"
            onClick={() => {
              props.navBack();
              boldSection(null);
            }}
          >
            <circle
              id="Ellipse 2"
              cx="233"
              cy="233"
              r="77"
              fill="#FFF584"
              // stroke={config.borderColor}
              filter="url(#dropshadow)"
              strokeWidth="4"
            />
            <text
              id="txt-amount"
              x="233"
              y="250"
              width="60"
              height="30"
              fontSize="38"
              fill={config.expenseForeColor}
              textAnchor="middle"
              strokeWidth="0"
            >
              <tspan id="txt-amount-inner-negative-sign" dy="0"></tspan>
              <tspan fontSize={"38"} dy="0">
                $
              </tspan>
              <tspan id="txt-amount-inner" dy="0">
                {/* {draggableExpense ? Math.round(draggableExpense.amount) : "50"} */}
                {/* {draggableExpense ? Math.round(categoryFigures.center) : "50"} */}
              </tspan>
              <tspan fontSize={"20"} id="txt-amount-original" dy="0">
                {/*
                {draggableExpense
                  ? Math.round(draggableExpense.original_amount)
                  : "50"}
                */}
                {/* {draggableExpense ? Math.round(categoryFigures.center) : "50"} */}
              </tspan>
            </text>
            <text
              id="txt-title-1"
              x="233"
              y="200"
              width="50"
              height="30"
              fontSize="16"
              fill={config.expenseForeColor}
              textAnchor="middle"
              strokeWidth="0"
            >
              {draggableExpense ? "Real Data" : "Starbucks"}
            </text>
            <text
              id="txt-date"
              x="233"
              y="280"
              width="50"
              height="30"
              fontSize="16"
              fill={config.expenseForeColor}
              textAnchor="middle"
              strokeWidth="0"
            >
              {draggableExpense ? "Real Data" : "Starbucks"}
            </text>
          </g>
        )}
        {/* end draggable expense */}
        <defs>
          <filter id="dropshadow">
            <feDropShadow
              dx="1"
              dy="2"
              stdDeviation="0.5"
              floodColor="#c7985f"
              floodOpacity="0.8"
            />
          </filter>
          <filter
            id="filter0_d_5629_74"
            x="-9.99835"
            y="-7.5"
            width="487"
            height="487"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_5629_74"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_5629_74"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </div>
  );
});

export default Tracker;

const EditContainer = styled.div`
  cursor: pointer;
  height: 40px;
  width: 40px;
  text-align: center;
  svg {
    height: 100%;
    text-align: center;
  }
`;
