import { Button } from "antd";

const MightyNetworksButton = () => {
  const handleClick = () => {
    window.open(
      "https://moneyflow-community.mn.co",
      "_blank",
      "noopener,noreferrer",
    );
  };

  return (
    <Button type="primary" onClick={handleClick}>
      Visit the MoneyFlow Community
    </Button>
  );
};

export default MightyNetworksButton;
