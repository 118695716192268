import styled from "styled-components";
import { useState, useEffect } from "react";
import { LeftOutlined } from "@ant-design/icons";

import {
  MainContainer,
  TitleText,
  TopRow,
  ContentHolder,
} from "../../../components/DrawerComponents/StyleConsts";
import { BigBlueButton, BigGreyButton } from "./StyleComponents";

export default function GoalDetails({ goal, back }) {
  console.log(goal);
  const name = goal.name;
  const targetAmount = parseFloat(goal.target_amount);
  const timeLeft =
    new Date(goal.target_date).getMonth() - new Date().getMonth(); //in months
  const perMonth = Math.round(targetAmount / (timeLeft + 1));
  return (
    <MainContainer
      gradient={`linear-gradient(180deg, rgba(241, 250, 255, 0.75) 0%, rgba(11, 145, 234, 0.45) 73.37%);`}
    >
      <BackButton
        data-cy="backBtn"
        onClick={() => {
          back();
        }}
      >
        <LeftOutlined /> Back
      </BackButton>
      <TopRow>Goal Details</TopRow>
      <ContentHolder>
        <FormContainer>
          <TextRow>
            <TitleText>{name}</TitleText>
            <TitleText>${targetAmount}</TitleText>
          </TextRow>
          <TextRow>
            <p>Apply {perMonth}$ a month</p>
            <p>{timeLeft} months left</p>
          </TextRow>
          <InputHolder>
            <StyledTextarea placeholder="Notes"></StyledTextarea>
            <CheckboxContainer>
              <Checkbox id="checkbox1" type="checkbox" />
              <label htmlFor="checkbox1">Make this your Top Goal</label>
            </CheckboxContainer>
            <CheckboxContainer>
              <Checkbox id="checkbox2" type="checkbox" />
              <label htmlFor="checkbox2">Completed</label>
            </CheckboxContainer>
          </InputHolder>
          <div style={{ display: "flex", gap: "5px" }}>
            <BigGreyButton>Delete Goal</BigGreyButton>
            <BigBlueButton>Edit</BigBlueButton>
          </div>
        </FormContainer>
      </ContentHolder>
    </MainContainer>
  );
}

const BackButton = styled.button`
  border-radius: 21px;
  width: 80px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 2px 8px 0px #ffc83abf;
  padding: 6px;
  border: none;
  margin-right: auto;
  margin-left: 10%;
`;

const FormContainer = styled.div`
  box-shadow: 0px 2px 10px 0px #0b0d1626;
  border-radius: 22px;
  padding: 10px 15px 10px 15px;
`;

const TextRow = styled.div`
  display: flex;
  justify-content: space-between;
  p {
    margin: 0px;
  }
`;

const InputHolder = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StyledTextarea = styled.textarea`
  width: 100%;
  height: 100px;
  border-radius: 15px;
  background: #f6f6f6;
  border: 1.5px solid #d9d9d9;
  padding: 10px;
  font-size: 16px;
  resize: none;
  margin-top: 1rem;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Checkbox = styled.input`
  /* Your styles here */
`;
