import styled from "styled-components";

export const BigBlueButton = styled.button`
  background: linear-gradient(180deg, #3590ff 0%, #8dc1ff 171.11%);
  box-shadow: 0px 2px 6px 0px #2180f473;
  color: white;
  border: none;
  border-radius: 40px;
  width: 100%;
  margin-top: 1rem;
  height: 3rem;
  font-size: 18px;
  font-weight: 500;
`;

export const BigGreyButton = styled.button`
  background: #ffffff;
  color: #0b0d1680;

  border: 1px solid #0b0d1680;
  border-radius: 40px;
  width: 100%;
  margin-top: 1rem;
  height: 3rem;
  font-size: 18px;
  font-weight: 500;
`;
