import { BigBlueButton } from "./StyleComponents";
import styled from "styled-components";
import GoalOverview from "./GoalOverview";
import getIcontSVG from "../IconHelper";

import { ReactComponent as cashIcon } from "../../../assets/img/GoalWheel/goal-cash-icon.svg";

export default function ShortContent({
  setGoalModalOpen,
  categoryNames,
  currentCategoryInt,
  totalApplied,
  totalNeeded,
  totalAssets,
}) {
  const SVG = getIcontSVG(currentCategoryInt);
  const dateString = "Jan 2025";
  return (
    <div style={{ width: "80%", marginLeft: "auto", marginRight: "auto" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <SVG height={"24px"} />
          <HeaderText fontSize={"24px"}>
            {categoryNames[currentCategoryInt]}
          </HeaderText>
        </div>
        <DateText>{dateString}</DateText>
      </div>
      <GoalOverview
        category={currentCategoryInt}
        totalApplied={totalApplied}
        totalNeeded={totalNeeded}
        totalAssets={totalAssets}
      />
    </div>
  );
}

const HeaderText = styled.p`
  font-size: ${(props) => (props.fontSize ? props.fontSize : "24px")};
  line-height: 33px;
  margin-right: auto;
  margin-left: 10px;
`;

const DateText = styled.p`
  font-size: 18px;
  line-height: 25px;
  text-align: right;
  color: #9d9d9d;
`;
