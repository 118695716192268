import { useRef } from "react";
import { Button, Modal, Form, Select } from "antd";

export default function DateChangerModal({
  form1,
  isOpen,
  setIsOpen,
  currentMonth, // eg "Jan"
  onChangeMonth,
  viewYear,
  onChangeYear,
  aYears,
  setDateToCurrent,
}) {
  const rollbackMonth = useRef(currentMonth);
  const rollbackYear = useRef(viewYear);

  const handleAfterOpenChange = (visible) => {
    if (visible) {
      // This code occurs when the modal opens,
      // (not component mount or rerender).
      // Save original values when the modal opens,
      // in case user decides to cancel the modal.
      rollbackMonth.current = currentMonth;
      rollbackYear.current = viewYear;
      console.log(
        `handleAfterOpenChange(): rollback ${rollbackMonth.current} / ${rollbackYear.current}`,
      );
    }
  };

  function closeModal() {
    setIsOpen(false);
  }

  async function onFinish(values) {
    console.log("DateChangeModal: onFinish() has values:");
    console.log(values);
  }

  function onFinishFailed(errorInfo) {}

  // When user changes the Select value, the date
  // (and corresponding expense data) changes
  // immediately for faster responsiveness, instead of
  // waiting until the user clicks on the "OK" button.
  return (
    <Modal
      title="Pick a Month"
      open={isOpen}
      onOk={closeModal}
      onCancel={() => {
        onChangeMonth(rollbackMonth.current);
        onChangeYear(rollbackYear.current);
        closeModal();
      }}
      afterOpenChange={handleAfterOpenChange}
      data-cy="dateChangerModal"
    >
      <Form
        form={form1}
        name="monthChanger"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{
          month: currentMonth,
          year: viewYear,
        }}
      >
        <Form.Item name="month">
          <Select
            onChange={onChangeMonth}
            options={[
              { value: "Jan", label: "Jan" },
              { value: "Feb", label: "Feb" },
              { value: "Mar", label: "Mar" },
              { value: "Apr", label: "Apr" },
              { value: "May", label: "May" },
              { value: "Jun", label: "Jun" },
              { value: "Jul", label: "Jul" },
              { value: "Aug", label: "Aug" },
              { value: "Sep", label: "Sep" },
              { value: "Oct", label: "Oct" },
              { value: "Nov", label: "Nov" },
              { value: "Dec", label: "Dec" },
            ]}
          ></Select>
        </Form.Item>
        <Form.Item name="year">
          <Select onChange={onChangeYear} options={aYears}></Select>
        </Form.Item>
      </Form>
      <Button onClick={setDateToCurrent}>Set to Today</Button>
    </Modal>
  );
}
