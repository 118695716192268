import { useEffect, useState, useRef } from "react";
import { useCheckResponseFail } from "../../hooks/useCheckResponseFail";
import backend from "../../functions/backend";
import MoneyFlowHeader from "../../components/PageComponents/MoneyFlowHeader";
import BottomNavBar from "../../components/PageComponents/BottomNavBar";
import { Checkbox } from "antd-mobile";
import { Screen } from "../../styles/styledScreen";
import { PageTitle, BodyText } from "../../styles/styledPageComponents";
import "../../styles/IPadLook.css";

export default function Marketplace() {
  const checkResponseFail = useCheckResponseFail();
  const marketplaceSettingsId = useRef(-1);
  const [isOffersChecked, setIsOffersChecked] = useState(false);

  async function getMarketplaceSettings() {
    let query = "/v1/marketplace/settings";
    const response = await backend.get(query);
    checkResponseFail(response, "Failed to GET marketplace settings");

    let is_open_to_offers = false;
    if (response && response.items.length >= 1) {
      is_open_to_offers = response.items[0].is_open_to_offers;
      marketplaceSettingsId.current = response.items[0].id;
    }
    return is_open_to_offers;
  }

  async function postMarketplaceSettings(isChecked) {
    let query = "/v1/marketplace/settings";
    const data = {
      id: marketplaceSettingsId.current,
      is_open_to_offers: isChecked,
      is_setup_complete: true,
      setup_step: 1, // radio button has been changed by the user at least once
    };
    const response = await backend.post(query, data);
    checkResponseFail(response, "Failed to POST marketplace settings");
  }

  async function patchMarketplaceSettings(isChecked) {
    let query = "/v1/marketplace/settings";
    const data = {
      id: marketplaceSettingsId.current,
      is_open_to_offers: isChecked,
      is_setup_complete: true,
      setup_step: 1, // radio button has been changed by the user at least once
    };
    const response = await backend.patch(query, data);
    checkResponseFail(response, "Failed to PATCH marketplace settings");
  }

  useEffect(() => {
    (async () => {
      let is_open_to_offers = await getMarketplaceSettings();
      setIsOffersChecked(is_open_to_offers);
    })();
  }, []);

  // IN: isChecked: boolean
  function handleCheckboxChange(isChecked) {
    console.log("Checkbox was clicked", isChecked);
    console.log("Marketplace handleCheckboxChange");
    setIsOffersChecked(isChecked);
    // new users already have a marketplace settings resource
    patchMarketplaceSettings(isChecked);
  }

  return (
    <Screen>
      <MoneyFlowHeader />
      <PageTitle>Marketplace</PageTitle>
      <Checkbox checked={isOffersChecked} onChange={handleCheckboxChange}>
        I'm interested in learning about loan refinance offers
      </Checkbox>
      <BottomNavBar />
    </Screen>
  );
}
