import styled from "styled-components";
import { useState, useEffect } from "react";
import {
  MainContainer,
  TableHolder,
  TableElementsContainer,
  Table,
  MARGIN_LEFT,
  TitleText,
  ContentBackground,
} from "../../../components/DrawerComponents/StyleConsts";
import DateSelector from "../../../components/DrawerComponents/DateSelector";
import BasicSVGHeader from "../../../components/DrawerComponents/BasicSVGHeader";
import TableHeader from "../../../components/DrawerComponents/TableHeader";
import PageSelector from "../../../components/DrawerComponents/PageSelector";
import GoalEntry from "./GoalEntry";
import AssetEntry from "./AssetEntry";
import GoalOverview from "./GoalOverview";
import TotalApplied from "./TotalApplied";
import GoalDetails from "./GoalDetails";
import getIcontSVG from "../IconHelper";

import { BigBlueButton } from "./StyleComponents";
import { ReactComponent as cashIcon } from "../../../assets/img/GoalWheel/goal-cash-icon.svg";

export default function TallContent({
  setGoalModalOpen,
  goalData,
  categoryNames,
  currentCategoryInt,
  totalApplied,
  totalNeeded,
  setAssetModalOpen,
  assetData,
  totalAssets,
}) {
  const tabNames = ["Assets", "Goals", "Overview"];
  const [tab, setTab] = useState(1);

  const [displayGoalDetails, setDisplayGoalDetails] = useState(false);
  const [selectedGoal, setSelectedGoal] = useState(null);

  const [searchFilter, setSearchFilter] = useState("");
  const goals = [];
  goalData.forEach((goal) => {
    if (goal.name.toLowerCase().includes(searchFilter.toLowerCase())) {
      goals.push(
        <div
          onClick={() => {
            setSelectedGoal(goal);
            setDisplayGoalDetails(true);
            console.log("Goal clicked");
          }}
        >
          <GoalEntry
            key={goal.id}
            goal={goal}
            editRow={() => {
              return;
            }}
          />
        </div>,
      );
    }
  });
  const assets = [];
  assetData.forEach((asset) => {
    if (asset.name.toLowerCase().includes(searchFilter.toLowerCase())) {
      assets.push(
        <div>
          <AssetEntry
            key={asset.id}
            asset={asset}
            editRow={() => {
              return;
            }}
          />
        </div>,
      );
    }
  });

  return displayGoalDetails ? (
    <GoalDetails
      goal={selectedGoal}
      back={() => {
        setDisplayGoalDetails(false);
      }}
    />
  ) : (
    <MainContainer
      gradient={`linear-gradient(180deg, rgba(241, 250, 255, 0.75) 0%, rgba(11, 145, 234, 0.45) 73.37%);`}
    >
      <div style={{ marginLeft: MARGIN_LEFT }}>
        <BasicSVGHeader
          headerLabel={categoryNames[currentCategoryInt]}
          SVG={getIcontSVG(currentCategoryInt)} //TODO use correct SVG
        />
      </div>
      {(tab === 0 || tab === 1) && (
        <TableHolder>
          <PageSelector
            selectedIndex={tab}
            setTab={setTab}
            tabNames={tabNames}
            highlightColor="#2180F4"
          />
          <div
            style={{
              width: "80%",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            {tab === 1 && <TotalApplied total={totalApplied} />}
            <BigBlueButton
              onClick={() => {
                if (tab === 1) {
                  setGoalModalOpen(true);
                } else {
                  setAssetModalOpen(true);
                }
              }}
            >
              + Add New {tabNames[tab].slice(0, -1)}
            </BigBlueButton>
          </div>
          <TitleText>All {tabNames[tab]}</TitleText>
          <Table>
            <TableHeader
              searchFunction={(criteria) => {
                setSearchFilter(criteria);
              }}
            />
          </Table>
        </TableHolder>
      )}
      {(tab === 0 || tab === 1) && (
        <TableElementsContainer>
          {tab === 1 ? goals : assets}
        </TableElementsContainer>
      )}

      {/* overview page */}
      {tab === 2 && (
        <>
          <TableHolder>
            <PageSelector
              selectedIndex={tab}
              setTab={setTab}
              tabNames={tabNames}
              highlightColor="#2180F4"
            />
            <TitleText></TitleText>
          </TableHolder>
          <ContentBackground>
            <GoalOverview
              category={currentCategoryInt}
              totalApplied={totalApplied}
              totalNeeded={totalNeeded}
              totalAssets={totalAssets}
            />
          </ContentBackground>
        </>
      )}
    </MainContainer>
  );
}
