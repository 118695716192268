import styled from "styled-components";
import { DownOutlined } from "@ant-design/icons";

export default function DateSelector({ dateString, editDate }) {
  return (
    <MainContainer
      onClick={() => {
        editDate();
      }}
    >
      {dateString}
      <DownOutlined style={{ marginLeft: "8px" }} />
    </MainContainer>
  );
}

const MainContainer = styled.button`
  box-shadow: 0px 4px 4px 0px #d4d4d466;
  background: #f3fff4cc;
  border-radius: 20px;
  border: none;
  height: 40px;
  padding: 10px 20px 10px 20px;

  align-self: flex-start;
`;
