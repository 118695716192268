import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../state/UserProvider";
import { useSettings, updateSetupStep1 } from "../../state/SettingsProvider";
import { getMoneyFlowToken } from "../../state/stateApplication";
import MoneyFlowHeader from "../../components/PageComponents/MoneyFlowHeader";
import { TopNavBarWheels } from "../../components/PageComponents/TopNavBar";
import BottomNavBar from "../../components/PageComponents/BottomNavBar";
import GoalsWheel from "../Tracker/GoalsWheel";
import InitTrackerConfig from "../ExpenseReview/InitTrackerConfig";
import styled from "styled-components";
import "../../styles/IPadLook.css";
import { Screen } from "../../styles/styledScreen";
import { BodyRegularText } from "../../styles/styledText";

export default function GoalsWheelPage() {
  const navigate = useNavigate();
  const { currentUser } = useUser();
  const { wheelSettings, dispatchSettings } = useSettings();

  const [isTour, setIsTour] = useState(
    false, // false, during Early Access
    // Need to use goal_settings' setup_step
    //wheelSettings.setup_step === wheelSettings.SETUP_STEP_GOALS_WHEEL_TOUR,
  );

  useEffect(() => {
    //setIsTour( wheelSettings.setup_step === wheelSettings.SETUP_STEP_GOALS_WHEEL_TOUR);
  }, [wheelSettings.setup_step]);

  useEffect(() => {
    if (!isTour) {
      console.log("useEffect().  isTour is false");
      if (getMoneyFlowToken() === "") {
        navigate("/", { replace: true });
      } else {
        if (
          wheelSettings.setup_step === wheelSettings.SETUP_STEP_GOAL_WHEEL_TOUR
        ) {
          updateSetupStep1(
            wheelSettings.SETUP_STEP_GOAL_WHEEL_MAIN,
            currentUser.goal_settings_id,
            dispatchSettings,
            "goal",
          );
        }
      }
    }
  }, [isTour]);

  const trackerConfig = InitTrackerConfig();
  trackerConfig.color = "#FFFFB4";
  trackerConfig.vectorColor = "#D4E7FF";

  return (
    <Screen>
      <MoneyFlowHeader />
      <TopNavBarWheels />
      <div className="tracker-form-split ActiveTracker">
        <GoalsWheel
          dropExpense={() => {}}
          expense={[]}
          config={trackerConfig}
          switchCategory={() => {}}
          showNumbers={false}
          categoryFigures={{}}
          isTour={isTour}
          setIsTour={setIsTour}
          navBack={() => {}}
          isExpenseReview={false}
          wheelType={"GoalsWheel"}
        />
      </div>
      <Text1>The Goals Wheel is disabled during Early Access.</Text1>
      <BottomNavBar />
    </Screen>
  );
}

const Text1 = styled.div`
  ${BodyRegularText}
  text-align: center;
  margin-top: 2em;
`;
