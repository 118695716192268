import styled from "styled-components";

export default function ExpenseCounterComponent({ count }) {
  function expenseCopy() {
    if (count === 0) {
      return (
        <span>
          <NumberHighlight id="noNumberHighlight">no</NumberHighlight> expenses
        </span>
      );
    } else if (count === 1) {
      return (
        <span>
          <NumberHighlight id="NumberHighlight">1</NumberHighlight> expense
        </span>
      );
    } else {
      return (
        <span>
          <NumberHighlight id="NumberHighlight">{count}</NumberHighlight>{" "}
          expenses
        </span>
      );
    }
  }

  console.log(count);
  return (
    <ReviewExpensesText>
      <div id="reviewExpensesText">You have {expenseCopy()} to sort.</div>
    </ReviewExpensesText>
  );
}

const NumberHighlight = styled.span`
  color: #357c03;
  font-family: PrimaryVariableFont, BoldFont, sans-serif;
  font-weight: 700;
`;

const ReviewExpensesText = styled.div`
  color: #333333;
  font-family: PrimaryVariableFont, RegularFont, sans-serif;
  font-style: normal;
  font-size: 1.875rem; /* 30 / 16 */
  font-weight: 400;
  line-height: 41px; /* 41 / 16 */
  letter-spacing: 0em;
  text-align: center;
`;
