import styled from "styled-components";

/* IN: progress: 0 - 100, is how far the highlighted progress bar is
 */
function ProgressBar({ progress }) {
  console.log("PROGRESS BAR: ", progress);
  const progressPips = [];
  for (let i = 0; i < 8; i += 1) {
    // there are 7 setup steps
    progressPips.push(<ProgressPip active={i < progress} key={i} />);
  }
  return <ProgressBar1 id="progressBar">{progressPips}</ProgressBar1>;
}

const ProgressBar1 = styled.div`
  position: sticky;
  top: 1rem;
  z-index: 1;
  width: 52%; /* Krista's Figma: 223/430 = 52% */
  max-width: 600px;
  height: 1.52vh; /* 14/921 */
  margin: 1em auto;

  /* responsive version for ipad/tablet sizes */
  /*
  @media (min-width: 768px) {
    margin: 0.9em auto;
  }
  */
  /* responsive version for desktop sizes */
  /*
  @media (min-width: 1200px) {
    margin: 0.6em auto;
  }
  */

  // background-color: var(--grey-progress-bar-background);
  // border-radius: 7px;
  display: flex;
  justify-content: space-between;
`;

const HighlightedProgressBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: ${(props) => props.progress}%;
  height: 100%;
  background: rgba(79, 129, 180, 0.75);
  border-radius: 7px;
`;

const ProgressPip = styled.div`
  // width: 12.5%;
  height: 120%;
  aspect-ratio: 1/1;
  border-radius: 100%;
  background: ${(props) => (props.active ? "#68cd74" : "#fafafa")};
  border: 1px solid #68cd74;
`;

export default ProgressBar;
