import TallContent from "./TallContent";
import ShortContent from "./ShortContent";

export default function DrawerContent({
  isDrawerShort,
  setGoalModalOpen,
  setAssetModalOpen,
  goalData,
  assetData,
  categoryNames,
  currentCategoryInt,
  totalApplied,
  totalNeeded,
  totalAssets,
}) {
  return isDrawerShort ? (
    <ShortContent
      setGoalModalOpen={setGoalModalOpen}
      categoryNames={categoryNames}
      currentCategoryInt={currentCategoryInt}
      totalApplied={totalApplied}
      totalNeeded={totalNeeded}
      totalAssets={totalAssets}
    />
  ) : (
    <TallContent
      setGoalModalOpen={setGoalModalOpen}
      setAssetModalOpen={setAssetModalOpen}
      goalData={goalData}
      assetData={assetData}
      categoryNames={categoryNames}
      currentCategoryInt={currentCategoryInt}
      totalApplied={totalApplied}
      totalNeeded={totalNeeded}
      totalAssets={totalAssets}
    />
  );
}
