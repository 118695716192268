import categoryData from "../../CFTSetup/Forms/Data";
import { ReactComponent as wheelIcon } from "../../../assets/img/Nav/wheels-icon-colored.svg";
import { ReactComponent as incomeIcon } from "../../../assets/img/Other/income-icon.svg";

//this function will take an index or a string and return the appropriate icon
const getIcontSVG = (iconName) => {
  switch (iconName) {
    case "All":
    case "All Categories":
    case 0:
      return wheelIcon;
    case "Family":
    case "Personal":
    case 1:
      return categoryData.Family.categoryTitleIcon;
    case "Food":
    case 2:
      return categoryData.Food.categoryTitleIcon;
    case "Home & Garden":
    case "Home":
    case 3:
      return categoryData["Home & Garden"].categoryTitleIcon;
    case "Entertainment":
    case 4:
      return categoryData.Entertainment.categoryTitleIcon;
    case "Health":
    case 5:
      return categoryData.Health.categoryTitleIcon;
    case "Transportation":
    case "Transport":
    case 6:
      return categoryData.Transportation.categoryTitleIcon;
    case "Utility":
    case 7:
      return categoryData.Utility.categoryTitleIcon;
    case "Other":
    case 8:
      return categoryData.Other.categoryTitleIcon;
    case "Income":
      return incomeIcon;
    default:
      return null;
  }
};

export default getIcontSVG;

export const categoryNames = [
  "All",
  "Personal",
  "Food",
  "Home",
  "Entertainment",
  "Health",
  "Transport",
  "Utility",
  "Other",
];

export const categoryColors = {
  All: " #68CD74",
  0: " #68CD74",
  Personal: "#0DD5CE",
  Family: "#0DD5CE",
  1: "#0DD5CE",
  Food: "#FFC634",
  2: "#FFC634",
  Home: "#64ABFF",
  3: "#64ABFF",
  Entertainment: "#FF773D",
  4: "#FF773D",
  Health: "#F11731",
  5: "#F11731",
  Transport: "#8729FF",
  6: "#8729FF",
  Utility: "#FF1292",
  7: "#FF1292",
  Other: "#68CD74",
  8: "#68CD74",
};
