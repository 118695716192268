export function isRunningTest() {
  return !!window.Cypress;
}

export function isDevelopment() {
  return process.env.NODE_ENV === "development";
}

// true for staging.moneyflow.org, preprod.moneyflow.org
// and app.moneyflow.org (aka production)
export function isDeployed() {
  return process.env.NODE_ENV === "production";
}
