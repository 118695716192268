import { Form, Select } from "antd";
import { useState, useEffect } from "react";
import styled from "styled-components";

const defaultRulesCoolFormSelect = [
  {
    required: false,
    message: "This field is required.",
  },
];

export default function CoolFormSelect({
  name,
  rules = defaultRulesCoolFormSelect,
  placeholder,
  options,
  hasInitialValue = false, // true, if we have given the form input an initial value
  required = false,
}) {
  const [moveText, setMovetext] = useState(false);
  useEffect(() => {
    if (hasInitialValue) {
      setMovetext(true);
    }
  }, [hasInitialValue]);

  const handleSelectChange = (val) => {
    if (val) {
      setMovetext(true);
    } else {
      setMovetext(false);
    }
  };

  return (
    <MainContainer>
      <SmallText>{moveText ? placeholder : ""}</SmallText>
      <Form.Item name={name} rules={rules}>
        <StyleSelect
          onChange={handleSelectChange}
          placeholder={placeholder}
          options={options}
        />
      </Form.Item>
    </MainContainer>
  );
}
const MainContainer = styled.div`
  position: relative;
  margin-top: 35px;
`;
const SmallText = styled.div`
  position: absolute;
  top: -10px;
  left: 10px;
  z-index: 10;
  background-color: white;
  flex-grow: 0;
  display: inline-block;
  padding: 0 4px;
  color: #68cd74;
  font-size: 0.75rem;
  max-height: 21px;
`;
const StyleSelect = styled(Select)`
  .ant-select-selector {
    &.ant-select-focused {
      border-color: #68cd74 !important;
    }
    border-color: #d9f7bd !important;
    border-radius: 12px;
  }
  height: 42px;
`;
