import { Layout } from "antd";
import { Link } from "react-router-dom";

const { Content } = Layout;

export default function AdminContent() {
  return (
    <Content>
      <h1>Admin Dashboard</h1>
      <Link to="/admin-notifications">Manage Notifications</Link>
      <br />
      <Link to="/admin-users">Manage Users</Link>
    </Content>
  );
}
