import { Modal, Form } from "antd";
import styled, { createGlobalStyle } from "styled-components";
import NewAssetForm from "./NewAssetForm";
import backend from "../../functions/backend";

export function AssetModal({ isOpen, setIsOpen, fetchData }) {
  const [form] = Form.useForm();
  function closeModal() {
    setIsOpen(false);
  }
  const onFinish = async (values) => {
    // console.log("Success:", values);
    console.log(values);
    const date = new Date(values.acquisition_date);
    const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
    const data = {
      acquisition_date: formattedDate,
      goal_category_id: values.goal_category_id,
      name: values.name,
      value: values.value,
      note: values.note,
    };

    const url = "/v1/asset/" + values.asset_type;

    const result = await backend.post(url, data);
    if (result.success) {
      fetchData();
      form.resetFields();
    }

    closeModal();
  };
  return (
    <>
      <GlobalStyle />
      <ModalStyle
        open={isOpen}
        onOk={closeModal}
        footer={null}
        onCancel={closeModal}
        className="test-test"
      >
        <NewAssetForm form={form} onFinish={onFinish} closeModal={closeModal} />
      </ModalStyle>
    </>
  );
}
const ModalStyle = styled(Modal)`
  box-shadow: 0px 2px 20px 0px #ffc83a;
  padding-bottom: 0px;
  border-radius: 30px;
  .ant-modal-content {
    border-radius: 30px;
  }
`;

const GlobalStyle = createGlobalStyle`
  .ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.2) !important;
    backdrop-filter: blur(5px); /* Adjust the blur radius as needed */
  }
`;
