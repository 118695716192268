import { css } from "styled-components";

/* These are from the MFO Brand Guidelines 5/2024

  These mixins can be included in a styled-component.  For example use SubheaderText
  in a further customized component like these examples:

      export const PageTitle = styled.h1`
        ${SubheaderText};
      `;

      export const FormQuestion = styled.h1`
        ${SubheaderText};
        margin-bottom: 0.6rem;
      `;

  AccentText
      Accent Font: Poetson One Regular
      Can be used sparingly in decorative headers, design assets, or focal points.
      Can be used in all caps, mixed case, or all lowercase.
      Never use for long paragraphs, small text, or when readability is the first goal.

  HeaderText
      Header Font: ASAP Condensed Semibold
      Most often used with mixed casing, occasional all caps if design requires.
      Do not use for small text, or large paragraphs.

  SubheaderText
      Also Nunito.

  BodyRegularText
  BodyMediumTextCss
  BodySemiboldTextCss
      Can use Nunito in regular, medium, or semibold.
      Never use in all caps, only mixed or lowercase.
      Never use for headers or large text.
      Best used when readability is most important.
*/

export const AccentText = css`
  /*
  TODO: define this in globals.css
  Poetson One Regular
  font-family:

  TODO: need to pick good looking values here
  font-size:
  font-weight:
  font-style:
  line-height:

  text-align: center;
  color: var(--grey-dark);
  */
`;

export const HeaderText = css`
  /*
  TODO: define this in globals.css
  Header Font: ASAP Condensed Semibold
  font-family:

  TODO: need to pick good looking values here
  font-size:
  font-weight:
  font-style:
  line-height:

  text-align: center;
  color: var(--grey-dark);
  */
`;

/* Used for CFTSetup Question */
export const SubheaderText = css`
  font-family: PrimaryVariableFont, TitleFont, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 1.875rem; /* 30/16 */
  line-height: 2.5625rem; /* 41/16 */
  /* line-height 1.3; because line-height is often 1.3 * font-size */
  text-align: center;
  color: var(--grey-dark);
`;

export const BodyLargerText = css`
  font-family: PrimaryVariableFont, TitleFont, sans-serif;
  font-size: 1.25rem; /* 20/16 */
  font-weight: 700;
  line-height: 1.6875rem; /* 27/16 */
  text-align: center;
  color: var(--grey-dark);
`;

/* do not export; private to this file */
const BodyText = css`
  font-family: PrimaryVariableFont, TitleFont, sans-serif;
  font-size: 1rem; /* 16/16 */
  line-height: 1.375rem; /* 22/16 */
  text-align: center;
  color: var(--grey-dark);
`;

/* Used for CFTSetup Body Text */
export const BodyRegularText = css`
  ${BodyText};
  font-weight: 400;
`;

export const BodyMediumTextCss = css`
  ${BodyText};
  font-weight: 500;
`;

export const BodySemiboldTextCss = css`
  ${BodyText};
  font-weight: 600;
`;
