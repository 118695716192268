import {
  BodyRegularText,
  SubheaderText,
  BodySemiboldTextCss,
} from "./styledText";
import styled from "styled-components";

export const PageTitle = styled.h1`
  ${SubheaderText};
`;

export const BodyText = styled.p`
  ${BodyRegularText};
`;

export const BodySemiboldText = styled.p`
  ${BodySemiboldTextCss};
`;

export const FormTitle = styled.div`
  margin: 30px 0 20px 0;
  position: relative;
  width: 100%;
  /* outline: 1px solid red; */
  font-family: TitleFont;
  font-size: 2rem; /* 20/16=1.25rem */
`;

export const FormItemContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 0;

  /* outline: 1px solid blue; */
  font-family: TitleFont;
  font-size: 1rem; /* 20/16=1.25rem */
  font-weight: 700;
  line-height: 1.6875rem; /* 27/16 */
  text-right: center;
`;

export const FormWrapper2 = styled.div`
  padding: 0;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 22px;
  position: relative;
  width: 100%;
  max-width: 430px;
  /* outline: 2px solid green; */
`;

export const ScrollingFormWrapper2 = styled(FormWrapper2)`
  overflow: initial;
`;

export const ScrollingAboveBottomNavBar = styled(FormWrapper2)`
  /* BottomNavBar is 72px tall.  margin-bottom keeps the form above the BottomNavBar. */
  margin-bottom: 88px;
`;
