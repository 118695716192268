import CoolFormInput from "./CoolFormInput";

// Use when you allow only a username, and not an email or phone number
export default function CoolFormInputUsername({
  name,
  placeholder,
  hasInitialValue = false,
  required = false,
}) {
  return (
    <CoolFormInput
      name={name}
      placeholder={placeholder}
      hasInitialValue={hasInitialValue}
      rules={[
        {
          required: required,
          message: "Please enter your username.",
        },
        { min: 5, message: "Username must be at least 5 characters." },
        { max: 80, message: "Username must be at most 80 characters." },
        {
          message:
            "Username can only include lowercase letters, numbers, and " +
            "underscores.  Must start with a lowercase letter.",
          pattern: /^[a-z][a-z0-9_]+$/,
        },
      ]}
    />
  );
}
