import styled from "styled-components";

export default function GoalOverview({
  category,
  totalApplied,
  totalNeeded,
  totalAssets,
}) {
  const savingRate = 1.5;
  return category === 0 ? (
    <Main>
      <div>
        <GreyText>Total Assets</GreyText>
        <BlackText>${totalAssets}</BlackText>
      </div>
      <div>
        <GreyText>Your Saving Rate</GreyText>
        <BlackText fontSize={`34px`}>{savingRate}%</BlackText>
      </div>
    </Main>
  ) : (
    <MainCategory>
      <InnerCategory>
        <p className="bold">${totalApplied}</p>
        <p className="small">Total Applied</p>
      </InnerCategory>
      <InnerCategory>
        <p className="bold">${totalNeeded}</p>
        <p clasName="small">Total Needed</p>
      </InnerCategory>
    </MainCategory>
  );
}

const Main = styled.div`
  display: flex;
  border: 1.5px solid #2180f4;
  border-radius: 15px;
  width: 100%;
  padding: 1rem;
  justify-content: space-between;
`;

const GreyText = styled.p`
  color: #0b0d16bf;
  font-size: 10px;
  margin-bottom: 0px;
`;

const BlackText = styled.p`
  color: #0b0d16;
  font-size: ${(props) => (props.fontSize ? props.fontSize : `20px`)};
  margin-top: 0px;
  margin-bottom: 0px;
`;

const MainCategory = styled.div`
  border: 1px solid #ff773d;
  border-radius: 18px;
  margin-top: 10px;
  box-shadow: 0px 2px 10px 0px #b6b6b6cc;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: #0b0d16;
  padding: 0.5rem;
  p {
    margin: 0;
  }
  .bold {
    font-weight: 800;
    font-size: 36px;
  }
  .small {
    font-weight: 500;
    font-size: 12px;
  }
`;

const InnerCategory = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;
