import { Form } from "antd";
import { useState } from "react";
import { useDisplayErrorMsg } from "../../hooks/useDisplayErrorMsg";
import backend from "../../functions/backend";
import styled from "styled-components";
import { SyncOutlined } from "@ant-design/icons";
import * as SP from "../../styles/styledPageComponents";
import GreenGradientBackground from "../../components/UI/GreenGradientBackground";
import CoolFormInputPassword from "../../components/UI/CoolFormInputPassword";
import MoneyFlowFooter from "../../components/PageComponents/MoneyFlowFooter";
import {
  MainContainer,
  TitleText,
  ReminderText,
  GreenLink,
  BigGreenButton,
  SmallText,
} from "../../styles/styledLoginComponents";

export default function ResetContent() {
  const [passwordSet, setPasswordSet] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { displayErrorMsg } = useDisplayErrorMsg();
  const titleText = passwordSet ? (
    <>
      You're all <br /> Set!{" "}
    </>
  ) : (
    <>
      Reset
      <br /> Password{" "}
    </>
  );
  const reminderText = passwordSet ? (
    <>You're password has been reset.</>
  ) : (
    <>Almost done!</>
  );
  const messageText = passwordSet ? (
    <>
      Now go get yourself a little treat.
      <br />
      You deserve it.
    </>
  ) : (
    <>
      Enter your new password and
      <br />
      you'll be back in the flow.
    </>
  );
  const buttonText = passwordSet ? <>Back to Sign In</> : <>Rest Password</>;

  const onFinish = async (values) => {
    setIsLoading(true);
    console.log("Success: " + JSON.stringify(values));
    const currentUrl = window.location.href;
    const token = currentUrl.split("/").pop();
    const payload = { token: token, password: values.password };
    let data = await backend.patch("/v1/user/reset_password", payload);
    if (!data.success) {
      alert("Error Setting pasword.");
    } else {
      setPasswordSet(true);
    }
    setIsLoading(false);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed: " + JSON.stringify(errorInfo));
  };

  return (
    <MainContainer justifyContent={"flex-start"}>
      <GreenGradientBackground bottom={true} />
      <Form
        style={{ maxWidth: 430, width: "90%", marginTop: "10%" }}
        layout="vertical"
        name="basic"
        onFinish={(values) => onFinish(values)}
        onFinishFailed={onFinishFailed}
      >
        <GreenLink to="/login" size={12}>
          {"<Back to Sign In"}
        </GreenLink>
        <TitleText style={{ textAlign: "start", marginTop: "1em" }}>
          {titleText}
        </TitleText>
        <ReminderText style={{ textAlign: "start" }}>
          {reminderText}
        </ReminderText>
        <SmallText
          style={{ textAlign: "start", marginTop: "10%", marginBottom: "20%" }}
        >
          {messageText}
        </SmallText>
        {isLoading && (
          <LoadingIcon>
            <SyncOutlined spin />
          </LoadingIcon>
        )}
        {!passwordSet && (
          <CoolFormInputPassword placeholder="New Password" required={true} />
        )}

        <Form.Item>
          {" "}
          {/* margin + size of entry field */}
          <BigGreenButton type="primary" htmlType="submit">
            {buttonText}
          </BigGreenButton>
        </Form.Item>
      </Form>
      <div
        style={{
          marginBottom: "2em",
          textAlign: "center",
          position: "absolute",
          bottom: "0px",
        }}
      >
        <p>
          Don't have an account?{" "}
          <span>
            <GreenLink to="/register" id="registerLink" size={14}>
              Create Account
            </GreenLink>
          </span>
        </p>
      </div>
      <div style={{ marginTop: "auto" }}>
        <MoneyFlowFooter />
      </div>
    </MainContainer>
  );
}
const LoadingIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5em;
  margin-bottom: 1em;
  font-size: 7rem;
`;
