import styled from "styled-components";
import { ReactComponent as MinusCircle } from "../../../assets/img/Other/MinusCircle.svg";
import { ReactComponent as IncomeIcon } from "../../../assets/img/Other/income-icon.svg";
import backend from "../../../functions/backend";
import { categoryNames } from "./IconHelper";
import { monthABV } from "../../../functions/timeDate";
import { useState, useEffect } from "react";

export default function PlanContent({
  budgetInfo,
  changePlanAmount,
  category,
  viewMonth,
  viewYear,
}) {
  const [editing, setEditing] = useState(false);
  const [plannedAmount, setPlannedAmount] = useState(budgetInfo.budgetAmount);
  const [lastMonthValues, setLastMonthValues] = useState(null);
  const [lastYearValues, setLastYearValues] = useState(null);
  useEffect(() => {
    setPlannedAmount(budgetInfo.budgetAmount);
  }, [budgetInfo.budgetAmount]);
  async function patchInfo() {
    const values = { planned: plannedAmount };
    changePlanAmount(values);
  }
  async function getPastValues() {
    let query = "/v1/money_wheel/budget?year=";
    // for "/v1/money_wheel/budget/", month can be 1..12
    if (viewMonth - 1 === 0) {
      // last month is Dec of previous year
      query += viewYear - 1 + "&month=" + 12;
    } else {
      query += viewYear + "&month=" + (viewMonth - 1);
    }
    query += "&tx_category_id=" + budgetInfo.categoryID;

    let budget = await backend.get(query);
    console.log("last month's budget");
    //process the values
    if (budget.success && budget.items.length > 0) {
      setLastMonthValues({
        planned: Math.round(budget.items[0].budget_amount),
        spent: Math.round(budget.items[0].actual_amount),
      });
    } else {
      console.error("Error getting last month's budget");
      console.log(budget);
    }

    query =
      "/v1/money_wheel/budget?year=" + (viewYear - 1) + "&month=" + viewMonth;
    query += "&tx_category_id=" + budgetInfo.categoryID;
    budget = await backend.get(query);
    console.log("last year's budget");
    if (budget.success && budget.items.length > 0) {
      setLastYearValues({
        planned: Math.round(budget.items[0].budget_amount),
        spent: Math.round(budget.items[0].actual_amount),
      });
    } else {
      console.error("Error getting last years's budget");
      console.log(budget);
    }
  }
  useEffect(() => {
    getPastValues();
  }, [viewMonth, viewYear, category]);

  return (
    <MainContainer>
      <MainCard>
        <p>
          {" "}
          {editing ? (
            <ValueInput
              value={plannedAmount}
              onChange={(e) => {
                setPlannedAmount(
                  parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                );
              }}
            ></ValueInput>
          ) : (
            <span className="big">${budgetInfo.budgetAmount} </span>
          )}{" "}
          for {category} of ${budgetInfo.totalBudget} total plan
        </p>
        <SmallCardContainer>
          <SmallCard>
            <MinusCircle height="45px" />
            <SmallCardText>
              <p className="small">Spent</p>
              <p className="big">-${budgetInfo.actualSpend}</p>
            </SmallCardText>
          </SmallCard>
          <SmallCard>
            <IncomeIcon height="30px" />
            <SmallCardText>
              <p className="small">Remaining</p>
              <p className="big">${budgetInfo.remaining}</p>
            </SmallCardText>
          </SmallCard>
        </SmallCardContainer>
        <div style={{ display: "flex" }}>
          {editing && (
            <EditButton
              onClick={() => {
                patchInfo();
                setEditing(false);
              }}
            >
              Save
            </EditButton>
          )}

          <EditButton
            onClick={() => {
              setEditing(!editing);
            }}
          >
            {editing ? "Cancel" : "Edit"}
          </EditButton>
        </div>
      </MainCard>
      <LabelP>
        Last Month: {monthABV[viewMonth - 1 === 0 ? 11 : viewMonth - 2]}{" "}
        {viewMonth - 1 === 0 ? viewYear - 1 : viewYear}
      </LabelP>
      <PastContainer>
        <SmallCard>
          <MinusCircle height="45px" />
          <SmallCardText>
            <p className="small">Spent</p>
            <p className="big">-${lastMonthValues?.spent}</p>
          </SmallCardText>
        </SmallCard>
        <SmallCard>
          <IncomeIcon height="30px" />
          <SmallCardText>
            <p className="small">Planned</p>
            <p className="big">${lastMonthValues?.planned}</p>
          </SmallCardText>
        </SmallCard>
      </PastContainer>
      <LabelP>
        Last Year: {monthABV[viewMonth - 1]} {viewYear - 1}
      </LabelP>
      <PastContainer>
        <SmallCard>
          <MinusCircle height="45px" />
          <SmallCardText>
            <p className="small">Spent</p>
            <p className="big">-${lastYearValues?.spent}</p>
          </SmallCardText>
        </SmallCard>
        <SmallCard>
          <IncomeIcon height="30px" />
          <SmallCardText>
            <p className="small">Planned</p>
            <p className="big">${lastYearValues?.planned}</p>
          </SmallCardText>
        </SmallCard>
      </PastContainer>
    </MainContainer>
  );
}

const MainContainer = styled.div`
  background: #ffffff;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 73px; //bottom navbar height
  & > * {
    flex: 0;
  }
`;

const MainCard = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  width: 90%;
  padding: 20px;
  box-shadow: 0px 2px 10px 0px #0b0d1626;
  .big {
    font-size: 38px;
    font-weight: 400;
  }
`;
const SmallCardContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 5px;
  margin-top: 10px;
`;

const SmallCard = styled.div`
  border-radius: 40px;
  width: 44%;
  padding: 6px 25px 6px 25px;
  box-shadow: 0px 2px 10px 0px #0b0d1626;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const SmallCardText = styled.div`
  font-weight: 500;
  p {
    margin: 0;
  }
  .small {
    font-size: 10px;
  }
  .big {
    font-size: 20px;
  }
`;

const ValueInput = styled.input`
  font-weight: 500;
  font-size: 20px;
  border-radius: 20px;
  width: 100px;
  text-align: center;
`;

const EditButton = styled.button`
  border-radius: 40px;
  background: linear-gradient(180deg, #ff773d 0%, #e64e2f 174.51%);
  box-shadow: 0px 2px 8px 0px #0b0d161a;
  width: 40%;
  border: none;
  min-height: 51px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: auto;
  color: white;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  p {
    margin: auto;
  }
`;

const PastContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 10px 0;
`;

const LabelP = styled.p`
  font-size: 20px;
  font-weight: 400;
  margin: 5px;
`;
